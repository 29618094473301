import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicantsService } from "../services/applicants.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmMoving } from "../applicant-info/applicant-info.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { RemarksService } from "src/app/services/remarks.service";
import { LoanService } from "../services/loan.service";
import {
  ConfirmationDialogComponent,
  ImageViewerDialog,
} from "src/app/shared/dialogs/dialogs.component";
import { GeneratePdfService } from "../services/generate-pdf.service";
@Component({
  selector: "app-loan-info",
  templateUrl: "./loan-info.component.html",
  styleUrls: ["./loan-info.component.scss"],
})
export class LoanInfoComponent implements OnInit {
  loan_id: string;
  data: any = false
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  brgy = require("../../json/brgy.json");
  userAccount: any;
  loans: any = [];
  retailer_id: any;
  remarksColumns: string[] = ["Remarks", "Author", "DateCreated", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  remarks: MatTableDataSource<any>;
  remarksSrc: any[] = [];
  remarkForm: FormGroup = null;
  per_account_sme_id: string = null;
  selectedTool = ""
  selectedPrintable = ""
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _applicant: ApplicantsService,
    private dialog: MatDialog,
    private _remark: RemarksService,
    private _loan: LoanService,
    private _gPdf: GeneratePdfService,
  ) {
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    if (!this.userAccount.user_level) {
      this.retailer_id = null;
    } else {
      this.retailer_id =
        this.userAccount.user_level == 1
          ? this.userAccount._id
          : this.userAccount.retailer._id;
    }

    this.remarkForm = new FormGroup({
      remarkText: new FormControl(null),
      author: new FormControl(null),
    });

    this.per_account_sme_id =
      this._route.snapshot.queryParams["per_account_sme_id"];
  }

  ngOnInit() {
    this.loan_id = this._route.snapshot.params["loan_id"];
    this.getLoanDetails(this.loan_id);
  }

  remarksDataSource() {
    this.remarks = new MatTableDataSource(this.remarksSrc);
    this.remarks.sort = this.sort;
    this.remarks.paginator = this.paginator;
  }

  remarkTextError: boolean = false;
  remarkAuthorError: boolean = false;
  async submitRemark() {

    let value = this.remarkForm.value;
    if (value.remarkText == null || value.remarkText == "") {
      this.remarkTextError = true;
    }
    if (value.author == null || value.author == "") {
      this.remarkAuthorError = true;
    }

    if (
      (value.remarkText !== null || value.remarkText !== "") &&
      (value.author !== null || value.author !== "")
    ) {
      let res = await this._remark.saveRemark(
        this.loan_id,
        this.remarkForm.value
      );
      this.remarkForm.patchValue({
        remarkText: null,
        author: null,
      });
      this.remarksSrc.splice(0, 0, res);
      console.log("this.remarksSrc", this.remarksSrc);
      this.remarksDataSource();
    }
  }

  remarksTextChange() {
    this.remarkTextError = false;
  }

  authorTextChange() {
    this.remarkAuthorError = false;
  }

  viewingRemark: boolean = false;
  viewRemark(remark) {
    this.viewingRemark = true;
    console.log("remark", remark);
    this.remarkForm.patchValue({
      remarkText: remark.remarkText,
      author: remark.author,
    });
  }

  doneViewing() {
    this.viewingRemark = false;
    this.remarkForm.patchValue({
      remarkText: null,
      author: null,
    });
  }

  async getLoanDetails(loan_id) {
    this.loans = [];
    console.log("calling getLoanDetails...")
    let res = await this._applicant.getLoanDetails(loan_id);
    console.log("🚀 ~ LoanInfoComponent ~ getLoanDetails ~ res:", res)
    if (res) {
      this.remarksSrc = res.remarks;
      this.remarksDataSource();
      for (let i = 0; i < res.user.loans.length; i++) {
        if (this.retailer_id == null) {
          this.loans.push(res.user.loans[i]);
        } else if (res.user.loans[i].retailer == this.retailer_id) {
          this.loans.push(res.user.loans[i]);
        }
      }

      this.province.map((list: any) => {
        if (list.provCode == res.user.personalInformation.address.province) {
          res.user.personalInformation.address.provinceName = list.provDesc;
        }
      });

      this.province.map((list: any) => {
        if (res.financial) {
          if (list.provCode == res.financial.employerAddress.province) {
            res.financial.employerAddress.provinceName = list.provDesc;
          }
        }
      });

      let records = this.city.RECORDS;
      records.map((list: any) => {
        if (list.citymunCode == res.user.personalInformation.address.city) {
          res.user.personalInformation.address.cityName = list.citymunDesc;
        }

        if (res.financial) {
          if (list.citymunCode == res.financial.employerAddress.city) {
            res.financial.employerAddress.cityName = list.citymunDesc;
          }
        }
      });
      this.data = res;
      console.log("🚀 ~ LoanInfoComponent ~ getLoanDetails ~ this.data:", this.data)
    }
  }

  async reviewed() {
    let res = await this._applicant.updateToReviewed(
      this.loan_id,
      this.data.user._id
    );
    console.log("res", res);
    this.getLoanDetails(this.loan_id);
  }

  async processed() {
    console.log("clicked");
    let res = await this._applicant.updateToProcessed(
      this.loan_id,
      this.data.user._id
    );
    console.log("res", res);
    this.getLoanDetails(this.loan_id);
  }

  async forwarded() {
    let res = await this._applicant.updateToForwarded(
      this.loan_id,
      this.data.user._id
    );
    console.log("res", res);
    this.getLoanDetails(this.loan_id);
  }

  async allowEdit() {
    let res = await this._applicant.allowEdit(this.loan_id, this.data.user._id);
    console.log("res", res);
  }

  openImage(link) {
    window.open(link);
  }

  async moveToTrash(user) {
    const dialogRef = this.dialog.open(ConfirmMoving, {
      panelClass: "center-modal",
      data: "Test Folder",
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        let user_id = user._id;
        console.log("user", user_id);
        let res = await this._applicant.moveToTrash(user_id);
        if (res) {
          this._router.navigate([""]);
        }
      }
    });
  }

  async moveToDone(loan) {
    const dialogRef = this.dialog.open(ConfirmMoving, {
      panelClass: "center-modal",
      data: "Archived",
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        let loan_id = loan._id;
        console.log("loan_id", loan_id);
        let res = await this._applicant.moveToDone(
          loan._id,
          this.per_account_sme_id
        );
        if (res) {
          this._router.navigate([""]);
        }
      }
    });
  }

  async undo(loan) {
    let res = await this._applicant.undoMoving(
      loan._id,
      this.per_account_sme_id
    );
    if (res) {
      this._router.navigate([""]);
    }
  }

  async deleteLoan(user_id, loan_id) {
    console.log("user_id", user_id);
    console.log("loan_id", loan_id);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "center-modal",
      data: {
        title: "Are you sure?",
        message: "This action cannot be undone!",
        cancelText: "Cancel",
        confirmText: "Delete",
      },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        let res = await this._loan.deleteLoan(user_id, loan_id);
        console.log("res", res);
        for (let i = 0; i < this.data.user.loans.length; i++) {
          if (this.data.user.loans[i]._id == loan_id) {
            this.data.user.loans.splice(i, 1);
          }
        }
      }
    });
  }

  showMoreInfo(image) {
    const dialogRef = this.dialog.open(ImageViewerDialog, {
      panelClass: "",
      data: image,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result");
      }
    });
  }

  async printPdf() {
    let datas = this.data;
    datas["personalAttachment"] = datas.user.personalAttachment;
    datas["personalInformation"] = datas.user.personalInformation;
    // console.log(datas);
    this._gPdf.generatePdf(datas);
  }


  EditLoanInformation(data) {
    this._router.navigate([`customer-forms`], {
      queryParams: {
        loan_id: data._id,
      }
    })
  }

  EditLoanInformationCoMaker(data) {
    this._router.navigate([`customer-forms`], {
      queryParams: {
        loan_id: data._id,
        co_maker_account: true
      }
    })
  }

  printRequirementsPdf() {
    let datas = [];
    datas["requirements"] = this.data.requirements;
    datas["personalInformation"] = this.data.user.personalInformation;
    console.log(datas);
    this._gPdf.printRequirementsPdf(datas);
  }

  addCoMaker(user_id) {
    this._router.navigate([`customer-forms`], {
      queryParams: {
        add_co_maker_to_user_id: user_id,
      }
    })
  }

  viewCoMaker(co_maker_id_loan_id) {
    let url = this._router.createUrlTree([`loan-info`, co_maker_id_loan_id]);
    window.open(url.toString(), '_blank')
  }

  goToLoanMessages(user_id) {
    this._router.navigate([`messages/message`, user_id])
  }

  onSelectTools(action) {
    console.log('action: ', action);
    switch (action) {
      case "Add Co-Maker":
        this.addCoMaker(this.data.user._id)
        break;
      case "View Co-Maker":
        this.viewCoMaker(this.data.user.co_maker_id.loans[0])
        break;
      case "Edit Co-Maker Account":
        this.EditLoanInformationCoMaker(this.data)
        break;
      case "Move to Archive":
        this.moveToDone(this.data)
        break;
      case "Edit Loan Information":
        this.EditLoanInformation(this.data)
        break;
      case "Allow User to Edit":
        this.allowEdit()
        break;
    }
  }

  onSelectPrintable(action) {
    console.log('action: ', action);

    switch(action){
      case "Print Details" :
        this.printPdf()// need update base on custom fields
        break;
      case "Print Requirements" : 
        this.printRequirementsPdf()// need update base on custom fields
        break;
    }
  }
}
