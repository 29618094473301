import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})



export class ApplicantsService {
  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }


  async getApplicants() {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + "/loan/getLoans",)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async searchForApplicant(name){
    try {
      this._progress.show('Searching...')
      let res = (await this.http.get(environment.API + `/loan/searchForApplicant?name=${name}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  getMoreApplicants(lastItem) {
    try {
      return this.http.get(environment.API + `/loan/getMoreLoans/${lastItem}`)
    } catch (err) {
      console.log(err);
    }
  }

  async getTrashApplicants(retailer_id) {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getUsersTrash/${retailer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getAllTrashes() {
    try {
      this._progress.show('Fetching Test Data...')
      let res = (await this.http.get(environment.API + `/loan/getAllTrashes`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getDoneApplicants(retailer_id) {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getUsersDone/${retailer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getMoreDoneApplicants(retailer_id, itemsFetched){
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getMoreUsersDone/${retailer_id}/${itemsFetched}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getDoneApplicantsBranch(branch_id) {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getUsersDoneBranch/${branch_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getMoreDoneApplicantsBranch(branch_id, itemsFetched) {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getMoreUsersDoneBranch/${branch_id}/${itemsFetched}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }


  async getApplicant(id) {
    try {
      this._progress.show('Fetching Applicant...')
      console.log('environment.API', environment.API)
      let res = (await this.http.get(environment.API + `/user/getApplicantDetails/${id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }


  async updateApplicant(data) {
    try {
      console.log("data", data);
      this._progress.show('Updating Applicant...')
      let res = (await this.http.post(environment.API + "/user/updateUser", { data: data })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async moveToTrash(loan_id) {
    try {
      this._progress.show('Updating Applicant...')
      let res = (await this.http.post(environment.API + `/loan/moveToTrash/${loan_id}`, { loan_id: loan_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async moveToDone(loan_id, per_account_sme_id?) {
    try {
      this._progress.show('Updating Applicant...')
      let res = (await this.http.post(environment.API + `/loan/moveToDone/${loan_id}/${per_account_sme_id}`, { loan_id: loan_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async undoMoving(loan_id, per_account_sme_id?) {
    try {
      this._progress.show('Updating Applicant...')
      let res = (await this.http.post(environment.API + `/loan/undoMoving/${loan_id}/${per_account_sme_id}`, { loan_id: loan_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async verifyUpdate(id, data) {
    try {
      console.log("data", data);
      this._progress.show('Updating Applicant...')
      let res = (await this.http.post(environment.API + `/user/verifyUpdate/${id}`, { data: data })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getLoanDetails(loan_id) {
    try {
      console.log("calling getLoan API ...", loan_id);
      this._progress.show('Fetching loan please wait...')
      let res = (await this.http.get(environment.API + `/loan/getLoan/${loan_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async updateToReviewed(loan_id, user_id) {
    try {
      this._progress.show('Updating loan...')
      let res = (await this.http.get(environment.API + `/loan/reviewed/${loan_id}/${user_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Updated", 'success')
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async updateToProcessed(loan_id, user_id) {
    try {
      this._progress.show('Updating loan...')
      let res = (await this.http.get(environment.API + `/loan/processed/${loan_id}/${user_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Updated", 'success')
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async updateToForwarded(loan_id, user_id) {
    try {
      this._progress.show('Updating loan...')
      let res = (await this.http.get(environment.API + `/loan/forwarded/${loan_id}/${user_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Updated", 'success')
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }


  async allowEdit(loan_id, user_id) {
    try {
      this._progress.show('Updating loan...')
      let res = (await this.http.get(environment.API + `/loan/allowEdit/${loan_id}/${user_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Updated", 'success')
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getApplicantsForThisRetailer(retrialer_id) {
    try {
      this._progress.show("Fetching Applications...");
      let res = (await this.http.get(environment.API + `/loan/getApplicantsForThisRetailer/${retrialer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getMoreApplicantsForThisRetailer(retrialer_id, itemsFetched) {
    try {
      let res = (await this.http.get(environment.API + `/loan/getMoreApplicantsForThisRetailer/${retrialer_id}/${itemsFetched}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getAllApplicantsForThisRetailer(retrialer_id) {
    try {
      let res = (await this.http.get(environment.API + `/loan/getAllApplicantsForThisRetailer/${retrialer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getBranchApplicants(branch_id) {
    try {
      this._progress.show("Fetching Applications...");
      let res = (await this.http.get(environment.API + `/loan/getBranchApplicants/${branch_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getMoreBranchApplicants(branch_id, itemsFetched) {
    try {
      let res = (await this.http.get(environment.API + `/loan/getMoreBranchApplicants/${branch_id}/${itemsFetched}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async getAllDone() {
    try {
      this._progress.show('Fetching Applicants...')
      let res = (await this.http.get(environment.API + `/loan/getAllDone`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async updateDateApproved(loan_id, date) {
    try {
      this._progress.show('Update Date Approved...')
      let res = (await this.http.post(environment.API + `/loan/updateDateApproved/${loan_id}`, { date: date })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Date Approved Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async updateDateApprovedBranch(loan_id, date) {
    try {
      this._progress.show('Update Date Approved...')
      let res = (await this.http.post(environment.API + `/loan/updateDateApprovedBranch/${loan_id}`, { date: date })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Date Approved Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }


  async searchForApplicantBranch(name, branch_id){
    try {
      this._progress.show('Searching...')
      let res = (await this.http.get(environment.API + `/loan/searchForApplicantBranch?name=${name}&branch_id=${branch_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async searchForApplicantRetailer(name, retailer_id){
    try {
      this._progress.show('Searching...')
      let res = (await this.http.get(environment.API + `/loan/searchForApplicantRetailer?name=${name}&retailer_id=${retailer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async searchForApplicantRetailerDone(name, retailer_id){
    try {
      this._progress.show('Searching...')
      let res = (await this.http.get(environment.API + `/loan/searchForApplicantRetailerDone?name=${name}&retailer_id=${retailer_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async searchForApplicantRetailerBranchDone(name, branch_id){
    try {
      this._progress.show('Searching...')
      let res = (await this.http.get(environment.API + `/loan/searchForApplicantRetailerBranchDone?name=${name}&branch_id=${branch_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }


}
