import { Component, OnDestroy } from '@angular/core';
import { ProgressLoaderService } from './progress-loader.service';
import { Subscription } from 'rxjs';

/**
 * Displays a progress loader while an API request is in progress
 *
 * @export
 * @class ProgressLoaderComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss']
})
export class ProgressLoaderComponent implements OnDestroy {

  /**
   * progress status subscription
   * @type {Subscription}
   */
  subscription: Subscription

  /**
   * Should application display the progress loader
   */
  showLoader = false

  /**
   * Message to display in progress loader
   * @type {string}
   */
  message: string

  /**
   * Creates an instance of ProgressLoaderComponent.
   * @param {ProgressLoaderService} _progress
   */
  constructor(private _progress: ProgressLoaderService) { 
    this.subscribeToService()
  }

  /**
   * Unsubscribe from progress loader subscription
   */
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  /**
   * Subscribe to progress loader status on initialization
   */
  subscribeToService() {
    this.subscription = this._progress.getStatus().subscribe(message => {
      if (message) {
        this.showLoader = true
        this.message = message
      } else {
        this.showLoader = false
      }
    })
  }
}
