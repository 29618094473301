import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "src/environments/environment";
import {APIResponse} from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import { FilePreview } from "src/utils/interfaces";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  getOrganization = async (org_id) => {
    try {
      console.log('org_id', org_id);
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/organization/getOrganization/${org_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getOrganizations() {
    try {
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/organization/getOrganizations`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async save(data, photos: FilePreview[]){
    try{
    console.log("data", data);
    this._progress.show('Saving Organization...')

    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    for (let i = 0; i < photos.length; i++) {
      formData.append(`photo-${i}`, photos[i].file);
    }

    let res = (await this.http.post(environment.API + "/organization/createOrganization", formData)
      .toPromise()) as APIResponse;
    if (res.status === "success") {
      this._progress.hide();
      this._notify.message('Organization Saved!', 'Success')
      return res.result;
    }
    throw new Error(res.code);
    }catch(err){
      console.log("ERROR", err)
    }
  }

  async update(id, data, photos: FilePreview[]){
    try{
      console.log("data", data);
      this._progress.show('Updating organization...')

      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }

      let res = (await this.http.post(environment.API + `/organization/updateOrganization/${id}`,  formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('organization Updated!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }

  deleteOrganization = async (id) => {
    try{
      this._progress.show('Deleting Organization...')
  
      let res = (await this.http.post(environment.API + `/organization/deleteOrganization/${id}`, {})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('Organization deleted!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        this._progress.hide();
        console.log("ERROR", err)
      }
  }

  async updateFieldsForPI(org_id, fields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/organization/updateFieldsForPI/${org_id}`, { fields: fields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFB(org_id, fields, marriedFields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/organization/updateFieldsForFB/${org_id}`, { fields: fields, marriedFields: marriedFields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFS(org_id, fields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/organization/updateFieldsForFS/${org_id}`, { fields: fields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFields(org_id, fields, selfEmployedFields){
    try{
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/organization/updateOrganizationFields/${org_id}`, {fields: fields, selfEmployedFields: selfEmployedFields})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }

  getOrganizationNoAccount = async () => {
    try{
      let res = (await this.http.get(environment.API + "/organization/getOrganizationNoAccount")
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }

  getMember = async (member_id) => {
    try {
      console.log('ins_id', member_id);
      this._progress.show('Fetching Member Details...')
      let res = (await this.http.get(environment.API + `/organizationMember/getMember/${member_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  getMembers = async (org_id) => {
    try {
      console.log('org_id', org_id);
      this._progress.show('Fetching Members...')
      let res = (await this.http.get(environment.API + `/organizationMember/getMembers/${org_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  memberDone = async (member_id) => {
    try {
      this._progress.show('Moving members to archives...')
      let res = (await this.http.post(environment.API + `/organizationMember/memberDone`, {member_id})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Member moved to Archives!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ InsuranceService ~ memberDone= ~ error:", error)
    }
  }

  undoMemberDone = async (member_id) => {
    try {
      this._progress.show('Moving members to archives...')
      let res = (await this.http.post(environment.API + `/organizationMember/undoMemberDone`, {member_id})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Member moved to Members tab!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ OrganizationService ~ undoMemberDone= ~ error:", error)
    }
  }

  getDoneMembers = async (org_id) => {
    try {
      console.log('org_id', org_id);
      this._progress.show('Fetching Members...')
      let res = (await this.http.get(environment.API + `/organizationMember/getDoneMembers/${org_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ OrganizationService ~ getDoneMembers= ~ error:", error)
      
    }
  }

}
