import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApplicantsService } from "src/app/services/applicants.service";
import { LoanService } from "src/app/services/loan.service";
import { BranchService } from "src/app/services/branch.service";
import { CiService } from "src/app/services/ci.service";
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-dashboard-report',
  templateUrl: './dashboard-report.component.html',
  styleUrls: ['./dashboard-report.component.scss']
})
export class DashboardReportComponent implements OnInit {
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  brgy = require("../../json/brgy.json");
  retailer: any = null;
  branches: any[] = [];
  approved: number = 0;
  disApproved: number = 0;
  cancelled: number = 0;
  forVerification: number = 0;
  totalApp: number = 0;
  dataAB: any = [0, 0, 0, 0];
  dataABLabels: any = ["", "", "", ""];
  loanReleased: number = 0;
  aPT: number = 0;
  dataOAndO: any = [0,0];
  dataOAndOLabels: any = ["", ""];
  constructor(
    private _user: UserService,
    private _applicants: ApplicantsService,
    private _router: Router,
    private _loan: LoanService,
    private _branch: BranchService,
    private _ci: CiService
  ) { 
    this.retailer = JSON.parse(localStorage.getItem("user"));
  }
  
  ngOnInit() {
    this.getApplicantsForThisRetailer();
    this.getBranches();
  }

  doughnutPieChartData = [
    {
      data: this.dataAB,
    }
  ];
  doughnutPieChartLabels = this.dataABLabels;

  doughnutPieChartOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true
    },
  };

  doughnutPieChartColors = [
    {
      backgroundColor: [
        'rgba(255, 206, 86, 0.2)',
        'rgba(28, 29, 30, 0.9)',
        'rgba(255, 67, 56, 1)',
        'rgba(253, 235, 210, 1)'
      ],
      borderColor: [
        'rgba(255, 206, 86, 1)',
        'rgba(0,0,0,1)',
        'rgba(255, 67, 56, 1)',
        'rgba(253, 235, 210, 1)'
      ]
    }
  ];

  onlinOflineChart = [
    {
      backgroundColor: [
        'rgba(255, 206, 86, 0.2)',
        'rgba(28, 29, 30, 0.9)',
      ],
      borderColor: [
        'rgba(255, 206, 86, 1)',
        'rgba(0,0,0,1)',
      ]
    }
  ];
  onlinOflineChartData = [
    {
      data: this.dataOAndO,
    }
  ];

  lineChartData = [{
    label: '# of Votes',
    data: [10, 19, 3, 5, 2, 3],
    borderWidth: 1,
    fill: false
  }];

  lineChartLabels = ["2013", "2014", "2014", "2015", "2016", "2017"];

  lineChartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  lineChartColors = [
    {
      borderColor: 'rgba(255,99,132,1)'
    }
  ];

  async getBranches(){
    let res = await this._branch.getBranches(this.retailer._id)
    this.branches = res
    console.log("bracnhes", res);
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };
  async getApplicantsForThisRetailer() {
    let res = await this._applicants.getApplicantsForThisRetailer(this.retailer._id);
    this.totalApp = res.length;
    console.log(res);
    console.log("res.length", res.length);
    console.log("this.retailer", this.retailer);
    let approved = 0;
    let disApproved = 0;
    let cancelled = 0;
    let forVerification = 0;
    let loanReleased = 0;
    let allAplicantHours = 0;
    let allWreviewedDate = 0;
    let onlineApp = 0;
    let offlineApp = 0;
    let data = 0;
    let branches = this.branches;
      // computations of percentage 
    // Increase = New Number - Original Number
    // divide the increase by the original number and multiply the answer by 100.
    // number_format((float)$number, 2, '.', '');
    for (let i = 0; i < res.length; i++) {
      let user = res[i].user;

      if(res[i].status == "Approved"){
        approved += 1;
      } else if(res[i].status == "Disapproved") {
        disApproved += 1;
      } else if(res[i].status == "Cancelled"){
        cancelled += 1;
      } else {
        forVerification += 1;
      }
      if(res[i].date_approved && res[i].date_approved != null){
        loanReleased += 1;
      }
      if(res[i].reviewedDate){
        let date1 = new Date(res[i].createdAt);
        let date2 = new Date(res[i].reviewedDate);
        const diff = date2.getTime() - date1.getTime();
        const hours = diff / 1000 / 60 / 60;
        allAplicantHours += hours;
        allWreviewedDate += 1;
      }
      if(user.code && user.code != null){
        onlineApp += 1;
      } else {
        offlineApp += 1;
      }
    }
    this.branches = branches;
    this.approved = approved;
    this.disApproved = disApproved;
    this.cancelled = cancelled;
    this.forVerification = forVerification;
    this.loanReleased = loanReleased;
    this.dataAB = [
      approved, disApproved, cancelled, forVerification
    ];
    this.dataABLabels = [
        `Approved ${this.roundTo((approved / res.length) * 100, 2)}%`,
       `Disapproved ${this.roundTo((disApproved / res.length) * 100, 2)}%`,
        `Cancelled ${this.roundTo((cancelled / res.length) * 100, 2)}%`,
        `No Status ${this.roundTo((forVerification / res.length) * 100, 2)}%`
    ];
    this.aPT = Math.round(allAplicantHours / res.length);
    this.dataOAndO = [onlineApp, offlineApp]
    this.dataOAndOLabels = [
      `Online ${this.roundTo((onlineApp / res.length) * 100, 2)}%`,
     `Offline ${this.roundTo((offlineApp / res.length) * 100, 2)}%`,
  ];

    console.log("Approved =>", this.approved);
    console.log("Disapproved =>", disApproved);
    console.log("Cancelled =>", cancelled);
    console.log("For further verification =>", this.forVerification );
    console.log("loanReleased =>", loanReleased);
    console.log("allAplicantHours =>", allAplicantHours);
    console.log("allWreviewedDate =>", allWreviewedDate);
    console.log("onlineApp =>", onlineApp);
    console.log("offlineApp =>", offlineApp);
    console.log("branches =>", branches);
  }

}
