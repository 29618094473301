import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentTransactionsService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }


  async getRetailerTransactions(retailer_id){
    try{
      console.log('retailer_id', retailer_id)
      this._progress.show('Fetching Transactions...')
      let res = (await this.http.get(environment.API + `/transactions/getTransactionByRetailerID/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

}
