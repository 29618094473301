import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";


@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  async updateAssignedBranch(loan_id, branch) {
    try {
      this._progress.show('update loan status admin...')
      let res = (await this.http.post(environment.API + `/loan/updateLoanAssignedBranch/${loan_id}`, { branch: branch })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Assigned Branch Updated", 'success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateAssignedBranchSME(loan_id, branch, account_number) {
    try {
      this._progress.show('update loan status admin...')
      let res = (await this.http.post(environment.API + `/loan/updateLoanAssignedBranchSME/${loan_id}`, { branch: branch, account_number: account_number })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Assigned Branch Updated", 'success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }


  async updateAssignedCI(loan_id_or_customer_id, ci_id) {
    try {
      this._progress.show('update loan status admin...')
      let res = (await this.http.post(environment.API + `/loan/updateLoanAssignedCI/${loan_id_or_customer_id}`, { ci_id: ci_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Assigned CI Updated", 'success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateLoanStatusAdmin(loan_id_or_customer_id, status) {
    try {
      this._progress.show('update loan status admin...')
      let res = (await this.http.post(environment.API + `/loan/updateLoanStatusAdmin/${loan_id_or_customer_id}`, { status: status })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Loan Status", 'success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async deleteLoan(user_id, loan_id){
    try {
      this._progress.show('Deleting Loan...')
      let res = (await this.http.post(environment.API + `/user/deleteUserLoan`, { user_id: user_id, loan_id: loan_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Loan Deleted!", 'success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }
}
