import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import pdfmake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfmake.vfs = pdfFonts.pdfMake.vfs;
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class GeneratePdfService {
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) {}

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  async generatePdf(data) {
    this.province.map((list: any) => {
      if (list.provCode == data.personalInformation.address.province) {
        data.personalInformation.address.provinceName = list.provDesc;
      }
      if (data.financial && data.financial.employerAddress !== "") {
        if (list.provCode == data.financial.employerAddress.province) {
          data.financial.employerAddress.provinceName = list.provDesc;
        }
      }
    });

    let records = this.city.RECORDS;
    records.map((list: any) => {
      if (list.citymunCode == data.personalInformation.address.city) {
        data.personalInformation.address.cityName = list.citymunDesc;
      }
      if (data.financial && data.financial.employerAddress !== "") {
        if (list.citymunCode == data.financial.employerAddress.city) {
          data.financial.employerAddress.cityName = list.citymunDesc;
        }
      }
    });
    let logo = await this.getBase64ImageFromURL("/assets/images/DatosLogo.png");

    let remarksData = data.remarks ? data.remarks : [];
    // let remarksData = [];
    let remarksDatas = [
      [
        {
          text: [
            {
              text: "Remarks",
              bold: true,
              fontSize: 12,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Author",
              bold: true,
              fontSize: 12,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Date created",
              bold: true,
              fontSize: 12,
              alignment: "center",
            },
          ],
        },
      ],
    ];
    remarksData.forEach(function (data) {
      var dataRow = [];

      dataRow.push({
        text: data.remarkText,
        alignment: "center",
        fontSize: 10,
      });
      dataRow.push({
        text: data.author,
        alignment: "center",
        fontSize: 10,
      });
      dataRow.push({
        text: moment(data.createdAt).format("LL"),
        alignment: "center",
        fontSize: 10,
      });

      remarksDatas.push(dataRow);
    });

    var dd = {
      // footer: {
      //   columns: [
      //     {
      //       text: [
      //         { text: "Disclaimer: ", bold: true, fontSize: 11 },
      //         {
      //           text: "In accordance with international rules of the credit investigation industry, the information contained herein is for the reference of business decision makers only. Credit Inquiry and Data Analytics Inc., bears no legal responsibility. The report may not be copied in whole or in part or redistributed to any third party without written permission of our company.",
      //           fontSize: 10,
      //           // pageBreak: "after",
      //         },
      //         { text: "\n" },
      //         { text: "\n" },
      //       ],
      //     },
      //   ],
      //   margin: [ 40, 0, 40, 100]

      // },
      content: [
        {
          table: {
            widths: [280, "auto"],
            headerRows: 1,
            body: [
              [
                {
                  image: logo,
                  width: 180,
                  height: 55,
                  alignment: "left",
                  // border: [false, false, false, true],
                },
                {
                  text: [
                    { text: "\n" },
                    { text: "\n" },
                    { text: "\n" },
                    {
                      text: "CREDIT INQUIRY AND DATA ANALYTCS INC.,",
                      bold: true,
                      alignment: "right",
                      fontSize: 10,
                    },
                    { text: "\n" },
                    { text: "\n" },
                  ],
                  // border: [false, false, false, true],
                },
              ],
              [{ text: "\n" }, { text: "\n" }],
            ],
          },
          layout: "headerLineOnly",
        },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        {
          text: "CONTENTS",
          bold: true,
          alignment: "center",
        },
        {
          text: "Personal Information",
          alignment: "center",
        },
        {
          text: "Financial Information",
          alignment: "center",
        },
        {
          text: "Family Background",
          alignment: "center",
        },
        {
          text: "Requirements",
          alignment: "center",
        },

        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },

        {
          text: "APPLICATION REPORT",
          bold: true,
          alignment: "center",
          fontSize: 16,
        },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },

        {
          text: [
            { text: "Name of applicant: ", bold: true },
            {
              text: `${data.personalInformation.firstName} ${
                data.personalInformation.lastName
              } ${
                data.personalInformation.nameSuffix == "" ||
                data.personalInformation.nameSuffix == null
                  ? ""
                  : data.personalInformation.nameSuffix
              }`,
              decoration: "underline",
            },
          ],
        },
        // {
        //   text: [
        //     { text: "Loan ID: ", bold: true },
        //     { text: data.id, decoration: "underline" },
        //   ],
        // },
        {
          text: [
            { text: "Date Prepared: ", bold: true },
            {
              text: moment(data.createdAt).format("LL"),
              decoration: "underline",
            },
          ],
        },

        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },

        {
          text: "CREDIT INQUIRY AND DATA ANALYTCS INC.,",
          bold: true,
          fontSize: 12,
        },
        {
          text: "Address: Yamaha 3S Shop Maharlika Highway San Rafael San Pablo City 4000 Laguna",
          fontSize: 11,
        },
        {
          text: "Mob: 0927 808 4900",
          fontSize: 11,
        },
        {
          text: "Website: www.datos.ph",
          fontSize: 11,
        },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        {
          text: [
            { text: "Disclaimer: ", bold: true, fontSize: 11 },
            {
              text: "In accordance with international rules of the credit investigation industry, the information contained herein is for the reference of business decision makers only. Credit Inquiry and Data Analytics Inc., bears no legal responsibility. The report may not be copied in whole or in part or redistributed to any third party without written permission of our company.",
              fontSize: 10,
              // pageBreak: "after",
            },
          ],
        },
        {
          text: "\n",
          pageBreak: "before",
        },
        {
          text: "APPLICANT CREDIT INVESTIGATION REPORT",
          bold: true,
          fontSize: 12,
          color: "#002060",
        },
        {
          text: [
            { text: "Name of applicant: ", bold: true },
            {
              text: `${data.personalInformation.firstName} ${
                data.personalInformation.lastName
              } ${
                data.personalInformation.nameSuffix == "" ||
                data.personalInformation.nameSuffix == null
                  ? ""
                  : data.personalInformation.nameSuffix
              }`,
              decoration: "underline",
            },
          ],
        },
        // {
        //   text: [
        //     { text: "Loan ID: ", bold: true },
        //     { text: data.id, decoration: "underline" },
        //   ],
        // },
        { text: "\n" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
          ],
        },
        { text: "\n" },
        { text: "PERSONAL INFORMATION", decoration: "underline", bold: true },
        { text: "\n" },
        {
          table: {
            widths: ["auto", 194, 194],
            headerRows: 1,
            body: [
              [
                { text: "Full Name", fontSize: 10 },
                {
                  text: `${data.personalInformation.firstName} ${
                    data.personalInformation.lastName
                  } ${
                    data.personalInformation.nameSuffix == "" ||
                    data.personalInformation.nameSuffix == null
                      ? ""
                      : data.personalInformation.nameSuffix
                  }`,
                  fontSize: 10,
                },
              ],
              [
                { text: "Gender", fontSize: 10 },
                { text: data.personalInformation.gender, fontSize: 10 },
              ],
              [
                { text: "Civil Status", fontSize: 10 },
                { text: data.personalInformation.civilStatus, fontSize: 10 },
              ],
              [
                { text: "Birthday", fontSize: 10 },
                {
                  text: moment(data.personalInformation.birthday).format("LL"),
                  fontSize: 10,
                },
              ],
              [
                { text: "Email", fontSize: 10 },
                { text: data.personalInformation.email, fontSize: 10 },
              ],
              [
                { text: "Mobile Number", fontSize: 10 },
                { text: data.personalInformation.mobileNumber, fontSize: 10 },
              ],
              [
                { text: "Address", fontSize: 10 },
                {
                  text: `${data.personalInformation.address.street} ${data.personalInformation.address.barangay} ${data.personalInformation.address.cityName} ${data.personalInformation.address.provinceName}`,
                  fontSize: 10,
                },
              ],
              [
                { text: "How did you find us?", fontSize: 10 },
                { text: data.personalInformation.findUs, fontSize: 10 },
              ],
              [
                { text: "With existing loan/s", fontSize: 10 },
                { text: data.personalInformation.existingLoan, fontSize: 10 },
              ],
              [
                { text: "Kind of loans", fontSize: 10 },
                { text: data.personalInformation.kindOfLoans, fontSize: 10 },
              ],
              [
                { text: "PRIMARY ID", fontSize: 10 },
                { text: data.personalAttachment.primaryType, fontSize: 10 },
              ],
              [
                { text: "SECONDARY ID", fontSize: 10 },
                { text: data.personalAttachment.secondaryType, fontSize: 10 },
              ],
            ],
          },
          layout: "noBorders",
        },

        { text: "\n" },
        { text: "\n" },
        { text: "FINANCIAL INFORMATION", decoration: "underline", bold: true },
        { text: "\n" },
        {
          table: {
            widths: ["auto", 180, 180],
            headerRows: 1,
            body: [
              [
                { text: "Source of Income", fontSize: 10 },
                {
                  text: data.financial ? data.financial.soc : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Nature of work/Position", fontSize: 10 },
                {
                  text: data.financial ? data.financial.position : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Name of company/Employer", fontSize: 10 },
                {
                  text: data.financial ? data.financial.employer : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Company/Employer Address", fontSize: 10 },
                {
                  text: data.financial
                    ? `${data.financial.employerAddress.street} ${data.financial.employerAddress.barangay} ${data.financial.employerAddress.cityName} ${data.financial.employerAddress.provinceName}`
                    : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Contact Person", fontSize: 10 },
                {
                  text: data.financial ? data.financial.contactPerson : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Contact Number", fontSize: 10 },
                {
                  text: data.financial ? data.financial.contactNumber : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Date Hired", fontSize: 10 },
                {
                  text: data.financial
                    ? moment(data.financial.dateHired).format("LL")
                    : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Applicant Gross Income", fontSize: 10 },
                {
                  text: data.financial ? data.financial.agi : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Monthly Deductions", fontSize: 10 },
                {
                  text: data.financial ? data.financial.monthlyDeductions : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Other Source of Income", fontSize: 10 },
                {
                  text: data.financial ? data.financial.osoc : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Food Expense", fontSize: 10 },
                {
                  text: data.financial ? data.financial.food : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Travel Expense", fontSize: 10 },
                {
                  text: data.financial ? data.financial.travel : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Electric and water Expense", fontSize: 10 },
                {
                  text: data.financial ? data.financial.electricWater : "",
                  fontSize: 10,
                },
              ],
              [
                { text: "Internet Expense", fontSize: 10 },
                {
                  text: data.financial ? data.financial.internet : "",
                  fontSize: 10,
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        { text: "\n" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
          ],
        },
        // { text: "\n" },
        //    { text: "\n" },
        //    { text: "\n" },
        //{ text: "Net Disposable Income", bold: true },
        // { text: "\n" },
        // {
        //   text: [
        //     { text: "Disclaimer: ", bold: true, fontSize: 11 },
        //     {
        //       text: "In accordance with international rules of the credit investigation industry, the information contained herein is for the reference of business decision makers only. Credit Inquiry and Data Analytics Inc., bears no legal responsibility. The report may not be copied in whole or in part or redistributed to any third party without written permission of our company.",
        //       fontSize: 10,
        //       pageBreak: "after",
        //     },
        //   ],
        // },
        {
          text: "\n",
          pageBreak: "before",
        },

        {
          text: "APPLICANT CREDIT INVESTIGATION REPORT",
          bold: true,
          fontSize: 12,
          color: "#002060",
        },
        {
          text: [
            { text: "Name of applicant: ", bold: true },
            {
              text: `${data.personalInformation.firstName} ${
                data.personalInformation.lastName
              } ${
                data.personalInformation.nameSuffix == "" ||
                data.personalInformation.nameSuffix == null
                  ? ""
                  : data.personalInformation.nameSuffix
              }`,
              decoration: "underline",
            },
          ],
        },
        // {
        //   text: [
        //     { text: "Loan ID: ", bold: true },
        //     { text: data.id, decoration: "underline" },
        //   ],
        // },
        { text: "\n" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
          ],
        },
        { text: "\n" },
        { text: "FAMILY BACKGROUND", decoration: "underline", bold: true },
        { text: "\n" },
        data.personalInformation.civilStatus == "Single"
          ? {
              table: {
                widths: ["auto", 150, 150],
                headerRows: 1,
                body: [
                  [
                    { text: "No. of Siblings", fontSize: 10 },
                    {
                      text: data.family ? data.family.numberOfSiblings : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Mother’s Name", fontSize: 10 },
                    {
                      text: data.family ? data.family.mothersName : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Mother’s Source of Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.motherSourceOfIncome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Mother’s Gross Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.motherGrossIncome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Mother’s Contact Number", fontSize: 10 },
                    {
                      text: data.family ? data.family.motherContactNumber : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Father’s Name", fontSize: 10 },
                    {
                      text: data.family ? data.family.fathersName : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Father’s Source of Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.fatherSourceOfIncome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Father’s Gross Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.fatherGrossIncome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Father’s Contact Number", fontSize: 10 },
                    {
                      text: data.family ? data.family.fatherContactNumber : "",
                      fontSize: 10,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            }
          : {
              table: {
                widths: ["auto", 150, 150],
                headerRows: 1,
                body: [
                  [
                    { text: "No. of Children", fontSize: 10 },
                    {
                      text: data.family ? data.family.numberOfChildren : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Applicant’s Spouse Name", fontSize: 10 },
                    {
                      text: data.family ? data.family.spouseName : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Spouse’s Source of Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.spouseSourceOfIcome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Spouse’s Gross Income", fontSize: 10 },
                    {
                      text: data.family ? data.family.spouseGrossIncome : "",
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: "Spouse’s Contact Number", fontSize: 10 },
                    {
                      text: data.family ? data.family.spouseContactNumber : "",
                      fontSize: 10,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },

        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
        {
          text: "\n",
          pageBreak: "before",
        },
        {
          text: "APPLICANT CREDIT INVESTIGATION REPORT",
          bold: true,
          fontSize: 12,
          color: "#002060",
        },
        {
          text: [
            { text: "Name of applicant: ", bold: true },
            {
              text: `${data.personalInformation.firstName} ${
                data.personalInformation.lastName
              } ${
                data.personalInformation.nameSuffix == "" ||
                data.personalInformation.nameSuffix == null
                  ? ""
                  : data.personalInformation.nameSuffix
              }`,
              decoration: "underline",
            },
          ],
        },
        // {
        //   text: [
        //     { text: "Loan ID: ", bold: true },
        //     { text: data.id, decoration: "underline" },
        //   ],
        // },
        { text: "\n" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
          ],
        },
        { text: "\n" },
        { text: "Remarks", bold: true },
        { text: "\n" },
        {
          table: {
            widths: ["auto", 140, 90],
            headerRows: 1,
            body: remarksDatas,
          },
          // layout: "noBorders",
        },

        { text: "\n" },
        { text: "\n" },
        { text: "\n" },
      ],
    };

    pdfmake.createPdf(dd).open();
  }

  async printRequirementsPdf(data) {
    let images = [];
    let requirementsData =
      data.requirements && data.requirements.data ? data.requirements.data : [];
    var dd = {
      content: [
        {
          text: "APPLICANT CREDIT INVESTIGATION REPORT",
          bold: true,
          fontSize: 12,
          color: "#002060",
        },
        {
          text: [
            { text: "Name of applicant: ", bold: true },
            {
              text: `${data.personalInformation.firstName} ${
                data.personalInformation.lastName
              } ${
                data.personalInformation.nameSuffix == "" ||
                data.personalInformation.nameSuffix == null
                  ? ""
                  : data.personalInformation.nameSuffix
              }`,
              decoration: "underline",
            },
          ],
        },
        { text: "\n" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 },
          ],
        },
        { text: "\n" },
        { text: "REQUIREMENTS", decoration: "underline", bold: true },
        { text: "\n" },
        requirementsData.map(function (item, idx) {
          return [
            {
              stack: [
                { text: "\n" },
                {
                  text: item.name,
                  bold: true,
                  alignment: "center",
                },
                {
                  image: item.base64,
                  fit: [290, 290],
                  alignment: "center",
                },
              ],
            },
            { text: "\n" },
            idx % 2
              ? {
                  text: "\n",
                  pageBreak: "before",
                }
              : "",
          ];
        }),
      ],
    };
    pdfmake.createPdf(dd).open();
  }

  async generateABPDF(data) {
    let logo = await this.getBase64ImageFromURL("/assets/images/DatosLogo.png");
    let datas = data;
    let tableDatas = [
      [
        {
          text: [
            {
              text: "Name",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Account Number",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Quality of Customer",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Outstanding Balance",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Cut off period and Due Date",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Date of Last Payment",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
      ],
    ];
    datas.sort((a, b) => {
      if(a.name != null && b.name != null){
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }

    });
    datas.forEach(function (data) {
      var dataRow = [];
      dataRow.push({
        text: data.name,
        alignment: "center",
        fontSize: 9,
      });
      dataRow.push({
        text: data.account_number,
        alignment: "center",
        fontSize: 9,
      });
      dataRow.push({
        text: data.quality_of_customer,
        alignment: "center",
        fontSize: 9,
      });
      dataRow.push({
        text: "PHP" + data.outstanding_balance,
        alignment: "center",
        fontSize: 9,
      });
      if (data.dueDateSecond && data.dueDateSecond != null) {
        dataRow.push([
          {
            text: [
              {
                text:
                  data.cutoffPeriodFirstFrom +
                  "-" +
                  data.cutoffPeriodFirstTo +
                  "  ",
                fontSize: 9,
              },
              {
                text: data.dueDateFirst + "\n",
                fontSize: 9,
                color: "red",
              },
            ],
            alignment: "center",
          },
          {
            text: [
              {
                text:
                  data.cutoffPeriodSecondFrom +
                  "-" +
                  data.cutoffPeriodSecondTo +
                  "  ",
                fontSize: 9,
              },
              {
                text: data.dueDateSecond + "\n",
                fontSize: 9,
                color: "red",
              },
            ],
            alignment: "center",
          },
        ]);
      } else {
        dataRow.push({
          text: [
            {
              text:
                data.cutoffPeriodFirstFrom +
                "-" +
                data.cutoffPeriodFirstTo +
                "  ",
              fontSize: 9,
            },
            {
              text: data.dueDateFirst + "\n",
              fontSize: 9,
              color: "red",
            },
          ],
          alignment: "center",
        });
      }
      dataRow.push({
        text: data.date_of_last_payment
          ? moment(data.date_of_last_payment).format("LL")
          : "No Record",
        alignment: "center",
        fontSize: 9,
      });

      tableDatas.push(dataRow);
    });
    var dd = {
      content: [
        {
          table: {
            widths: [280, "auto"],
            headerRows: 1,
            body: [
              [
                {
                  image: logo,
                  width: 180,
                  height: 55,
                  alignment: "left",
                  // border: [false, false, false, true],
                },
                {
                  text: [
                    { text: "\n" },
                    { text: "\n" },
                    { text: "\n" },
                    {
                      text: "CREDIT INQUIRY AND DATA ANALYTCS INC.,",
                      bold: true,
                      alignment: "right",
                      fontSize: 10,
                    },
                    { text: "\n" },
                    { text: "\n" },
                  ],
                  // border: [false, false, false, true],
                },
              ],
              [{ text: "\n" }, { text: "\n" }],
            ],
          },
          layout: "headerLineOnly",
        },
        { text: "\n" },
        { text: "Account Balances", decoration: "underline", bold: true },
        { text: "\n" },
        {
          table: {
            widths: [90, "auto", "auto", "auto", "auto", "auto"],
            headerRows: 1,
            body: tableDatas,
          },
          // layout: "noBorders",
        },
        // ========================================================================================================================================================
      ],
    };
    pdfmake.createPdf(dd).open();
  }

  async printSOA(cDandAddress, data) {
    let logo = await this.getBase64ImageFromURL("/assets/images/DatosLogo.png");
    let tableDatas = [
      [
        {
          text: [
            {
              text: "SI/DR No.",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Description",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Date",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Current",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
        {
          text: [
            {
              text: "Non-current",
              bold: true,
              fontSize: 11,
              alignment: "center",
            },
          ],
        },
      ],
    ];
    let cur = 0;
    let ncur = 0;
    let ormaPayment = [];
    console.log("1 > 1", 1 > 1);
    data.forEach(function (dval, idx) {
      if (dval.sidrno_from_orma_payment.length) {
        if (dval.sidrno_from_orma_payment.length > 1) {
          let dval2 = dval.sidrno_from_orma_payment;
          dval2.forEach(function (dataV, ids) {
            ormaPayment.push({
              sidr: dval.sidrno_from_orma_payment[ids].sidr,
              amountpaid: dval.sidrno_from_orma_payment[ids].amountpaid,
              rebate: dval.sidrno_from_orma_payment[ids].rebate,
            });
          });
        } else {
          ormaPayment.push({
            sidr: dval.sidrno_from_orma_payment[0].sidr,
            amountpaid: dval.sidrno_from_orma_payment[0].amountpaid,
            rebate: dval.sidrno_from_orma_payment[0].rebate,
          });
        }
      }
    });
    console.log("ormaPayment", ormaPayment);
    data.forEach(function (datas, idx) {
      if (datas.sidrno) {
        let newArr = ormaPayment.filter(function (val) {
          return datas.sidrno == val.sidr;
        });
        let newAmount;
        if (newArr.length) {
          if(newArr[0].rebate && newArr[0].rebate != null){
            newAmount = (datas.amount - newArr[0].amountpaid) - newArr[0].rebate;
          } else {
            newAmount = datas.amount - newArr[0].amountpaid;
          }
        } else {
          if(datas.penalty && datas.penalty != null){
            newAmount = datas.amount + datas.penalty;
          } else {
            newAmount = datas.amount;
          }
        }

        let dateNow = new Date();
        let datasMonth = new Date(datas.date).getMonth();
        let dataDate = new Date(datas.date);
        let fDueDate = new Date();
        fDueDate.setMonth(datasMonth);
        fDueDate.setDate(cDandAddress.ddf);
        var dataRow = [];

        if (newAmount != 0) {
          dataRow.push({
            text: datas.sidrno,
            alignment: "center",
            fontSize: 9,
          });
          dataRow.push({
            text: datas.remarks,
            alignment: "center",
            fontSize: 9,
          });
          dataRow.push({
            text: moment(datas.date).format("LL"),
            alignment: "center",
            fontSize: 9,
          });
          if (cDandAddress.dds != null) {
            let nextM = new Date();
            if (cDandAddress.cmnms == "Current Month") {
              nextM.setMonth(datasMonth);
            } else {
              nextM.setMonth(datasMonth + 1);
            }
            nextM.setDate(cDandAddress.dds);
            let dataDay = dataDate.getDate();
            if (dataDay <= cDandAddress.ddf) {
              if (fDueDate >= dateNow) {
                cur += newAmount;
                dataRow.push({
                  text: newAmount,
                  alignment: "right",
                  fontSize: 9,
                });
                dataRow.push({
                  text: 0,
                  alignment: "right",
                  fontSize: 9,
                });
              } else {
                dataRow.push({
                  text: 0,
                  alignment: "right",
                  fontSize: 9,
                });
                dataRow.push({
                  text: newAmount,
                  alignment: "right",
                  fontSize: 9,
                });
                ncur += newAmount;
              }
            } else {
              if (nextM >= dateNow) {
                cur += newAmount;
                dataRow.push({
                  text: newAmount,
                  alignment: "right",
                  fontSize: 9,
                });
                dataRow.push({
                  text: 0,
                  alignment: "right",
                  fontSize: 9,
                });
              } else {
                dataRow.push({
                  text: 0,
                  alignment: "right",
                  fontSize: 9,
                });
                dataRow.push({
                  text: newAmount,
                  alignment: "right",
                  fontSize: 9,
                });
                ncur += newAmount;
              }
            }
          } else {
            if (fDueDate >= dateNow) {
              cur += newAmount;
              dataRow.push({
                text: newAmount,
                alignment: "right",
                fontSize: 9,
              });
              dataRow.push({
                text: 0,
                alignment: "right",
                fontSize: 9,
              });
            } else {
              dataRow.push({
                text: 0,
                alignment: "right",
                fontSize: 9,
              });
              dataRow.push({
                text: newAmount,
                alignment: "right",
                fontSize: 9,
              });
              ncur += newAmount;
            }
          }
          tableDatas.push(dataRow);
        }
      }
      if (data.length == idx + 1) {
        let dataRow1 = [];
        let dataRow2 = [];

        dataRow1.push({});
        dataRow1.push({});
        dataRow1.push({});
        dataRow1.push({});
        dataRow1.push({});
        tableDatas.push(dataRow1);
        tableDatas.push(dataRow1);
        tableDatas.push(dataRow1);
        tableDatas.push(dataRow1);
        tableDatas.push(dataRow1);
        

        dataRow2.push({});
        dataRow2.push({});
        dataRow2.push({});
        dataRow2.push({
          colSpan: 2,
          table: {
            widths: [100, "auto"],
            headerRows: 2,
            body: [
              [
                {
                  text: "Total Current",
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: cur,
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
              ],
              [
                {
                  text: "Total Non-Current",
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: ncur,
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
              ],
              [
                {
                  text: "Total Balance",
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: cur + ncur,
                  alignment: "left",
                  fontSize: 9,
                  bold: true,
                },
              ],
            ],
          },
          layout: "headerLineOnly",
        });
        dataRow2.push({});
        tableDatas.push(dataRow2);
      }
    });
    var dd = {
      content: [
        {
          table: {
            widths: [360, "auto"],
            headerRows: 1,
            body: [
              [
                {
                  text: [
                    {
                      text: cDandAddress.retailer.isRetailer == true ? cDandAddress.retailer.name : cDandAddress.retailer.retailer.name + "\n",
                      fontSize: 25,
                    },
                    {
                      text: [
                        {
                          text: "Address: ",
                          fontSize: 12,
                          bold: true,
                        },
                        {
                          text: cDandAddress.retailer.isRetailer == true ? cDandAddress.retailer.address : cDandAddress.retailer.retailer.address + "\n",
                          fontSize: 11,
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "Contact Number: ",
                          fontSize: 12,
                          bold: true,
                        },
                        {
                          text: cDandAddress.retailer.isRetailer == true ? cDandAddress.retailer.contactNumber : cDandAddress.retailer.retailer.contactNumber + "\n",
                          fontSize: 11,
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "Email Address: ",
                          fontSize: 12,
                          bold: true,
                        },
                        {
                          text: cDandAddress.retailer.isRetailer == true ? cDandAddress.retailer.email : cDandAddress.retailer.retailer.email + "\n",
                          fontSize: 11,
                        },
                      ],
                    },
                    { text: "\n" },
                  ],
                  // border: [false, false, false, true],
                },
                {
                  image: cDandAddress.retailer.base64
                    ? cDandAddress.retailer.base64
                    : logo,
                  fit: [90, 90],
                  alignment: "right",
                  // border: [false, false, false, true],
                },
              ],
              [{ text: "\n" }, { text: "\n" }],
            ],
          },
          layout: "headerLineOnly",
        },
        { text: "\n" },
        { text: "\n" },
        {
          table: {
            widths: ["20%", "80%"],
            headerRows: 1,
            body: [
              [
                {
                  text: "Billing Statement",
                  bold: true,
                  fontSize: 20,
                  alignment: "center",
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: "Bill To:",
                  bold: true,
                  fontSize: 11,
                  alignment: "left",
                },
                {},
              ],
              [
                { text: "Customer Name: ", bold: true, fontSize: 11 },
                { text: cDandAddress.account_name, fontSize: 10 },
              ],
              [
                { text: "Customer Address: ", bold: true, fontSize: 11 },
                {
                  text:
                    cDandAddress.street +
                    " " +
                    cDandAddress.barangay +
                    " " +
                    cDandAddress.city +
                    " " +
                    cDandAddress.province,
                  fontSize: 10,
                },
              ],
              [{ text: "\n" }, { text: "\n" }],
              [
                { text: "Cut off period", bold: true, fontSize: 11 },
                cDandAddress.dds != null
                  ? [
                      {
                        text: [
                          {
                            text:
                              cDandAddress.cpff +
                              " to " +
                              cDandAddress.cpft +
                              " and ",
                            fontSize: 11,
                          },
                          {
                            text:
                              cDandAddress.cpsf + " to " + cDandAddress.cpst,
                            fontSize: 11,
                          },
                        ],
                      },
                    ]
                  : {
                      text: cDandAddress.cpff + " to " + cDandAddress.cpft,
                      fontSize: 11,
                    },
              ],

              [
                { text: "Due Date", bold: true, fontSize: 11 },
                cDandAddress.dds != null
                  ? [
                      {
                        text: [
                          {
                            text:
                              cDandAddress.ddf +
                              " " +
                              cDandAddress.cmnmf +
                              " and ",
                            fontSize: 11,
                          },
                          {
                            text: cDandAddress.dds + " " + cDandAddress.cmnms,
                            fontSize: 11,
                          },
                        ],
                      },
                    ]
                  : {
                      text: cDandAddress.ddf + " " + cDandAddress.cmnmf,
                      fontSize: 11,
                    },
              ],
            ],
          },
          layout: "noBorders",
        },
        { text: "\n" },
        { text: "\n" },
        {
          table: {
            widths: ["auto", 180, "auto", "auto", "auto"],
            headerRows: 1,
            body: tableDatas,
          },
          layout: "noBorders",
        },
        { text: "\n\n\n\n\n\n\n\n" },
        {
          text: "Make all checks payable to " + cDandAddress.retailer.name,
          fontSize: 12,
          bold: true,
          alignment: "center",
        },
        {
          text: "Thank you for business with us!",
          fontSize: 11,
          bold: true,
          alignment: "center",
        },

        // ========================================================================================================================================================
      ],
    };
    pdfmake.createPdf(dd).open();
  }
}
