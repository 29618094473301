import { Injectable } from '@angular/core';
import {FilePreview} from "src/utils/interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";


@Injectable({
  providedIn: 'root'
})
export class SmeService {
  sme_record: any = null
  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }
  
    async getRetailerSMERecords(retailer_id) {
      try{
        console.log("retailer_id", retailer_id);
        let res = (await this.http.get(environment.API + `/sme/getRetailerSMERecords/${retailer_id}`)
        .toPromise()) as APIResponse
        if(res.status === "success"){
          return res.result
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR:", err);
      }
    }

    async getRecord(sme_id) {
      try{
        let res = (await this.http.get(environment.API + `/sme/getRecord/${sme_id}`)
        .toPromise()) as APIResponse
        if(res.status === "success"){
          return res.result
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR:", err);
      }
    }

  async createRecord(retailer_id, record, photos: FilePreview[]) {
    try {
      console.log("data", record);
      this._progress.show('Saving Record...')
      let formData = new FormData();
      formData.append("data", JSON.stringify(record));
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }
      let res = (await this.http.post(environment.API + `/sme/createRecord/${retailer_id}`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Record Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateRecord(sme_id, record, photos: FilePreview[], allUserSidr?) {
    try {
      this._progress.show('Saving Record...');

      let formData = new FormData();
      formData.append("data", JSON.stringify(record));
      if(allUserSidr){
        formData.append("allUserSidr", JSON.stringify(allUserSidr));
      }
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }

      let res = (await this.http.post(environment.API + `/sme/updateRecord/${sme_id}`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Record updated!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async uploadCsv(retailer_id, csv: FilePreview[]){
    try{
      this._progress.show('Uploading Records...');
      let formData = new FormData();
      formData.append(`csv`, csv[0].file);
      let res = (await this.http.post(environment.API + `/sme/uploadCsv/${retailer_id}`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Records Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);

    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async getAllSMEUsers(retailer_id) {
    try{
      console.log("retailer_id", retailer_id);
      let res = (await this.http.get(environment.API + `/sme/getAllSMEUsers/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async getSMEByAccountNumberRecords(accountNumber) {
    try{
      console.log("accountNumber", accountNumber);
      let res = (await this.http.get(environment.API + `/sme/getSMEByAccountNumberRecords/${accountNumber}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async createAccount(retailer_id, data){
    try{
      console.log("retailer_id", retailer_id);
      this._progress.show('Saving Account...')
      let res = (await this.http.post(environment.API + `/sme/createAccount/${retailer_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Account Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async getSMEbyRetailerID(retailer_id){
    try{
      console.log("retailer_id", retailer_id);
      let res = (await this.http.get(environment.API + `/sme/getSMEbyRetailerID/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async updateDateApproved(user_id, retailer_id, sme_id, date) {
    try {
      this._progress.show('Update Date Approved...')
      let res = (await this.http.post(environment.API + `/sme/updateDateApproved/${user_id}/${retailer_id}/${sme_id}`, { date: date })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Date Approved Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }


  async updateSMEStatus(user_id, retailer_id, sme_id, status) {
    try {
      this._progress.show('Update Status...')
      let res = (await this.http.post(environment.API + `/sme/updateSMEStatus/${user_id}/${retailer_id}/${sme_id}`, { status: status })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Status Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async updateTerms(user_id, retailer_id, sme_id, data) {
    try {
      this._progress.show('Update Status...')
      let res = (await this.http.post(environment.API + `/sme/updateTerms/${user_id}/${retailer_id}/${sme_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Terms Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async updateAffiliate(user_id, retailer_id, sme_id, data) {
    try {
      this._progress.show('Updating Company Name...')
      let res = (await this.http.post(environment.API + `/sme/updateAffiliate/${user_id}/${retailer_id}/${sme_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Company Name Updated!", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async updateCreditLimit(sme_id, data, user_id, retailer_id) {
    try {
      console.log(data.acl)
      this._progress.show('Update Credit Limit...')
      let res = (await this.http.post(environment.API + `/sme/updateCreditLimit/${sme_id}/${user_id}/${retailer_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Credit Limit Updated!", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }
  

  async getAllUserSMEByAccountNumber(retailer_id){
    try{
      this._progress.show("Fetching Accounts...");
      let res = (await this.http.get(environment.API + `/sme/getAllUserSMEByAccountNumber/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
      this._progress.hide();
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }


  async updateQualityOfCustomerByAccountNumber(account_number, quality_of_customer) {
    try {
      let res = (await this.http.post(environment.API + `/sme/updateQualityOfCustomerByAccountNumber/${account_number}`, { quality_of_customer: quality_of_customer })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Quality of Customer Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async getDoneSme(retailer_id){
    try{
      console.log("retailer_id", retailer_id);
      let res = (await this.http.get(environment.API + `/sme/getDoneSme/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }


  async getAgingSummary(retailer_id){
    try{
      this._progress.show("Fetching Accounts...");
      let res = (await this.http.get(environment.API + `/sme/getAgingSummary/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        this._progress.hide();
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }


  async getAllSidrNo(retailer_id){
    try{
      console.log("retailer_id", retailer_id);
      let res = (await this.http.get(environment.API + `/sme/getAllSidrNo/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async getAllSidrNoEvenIfPaid(retailer_id, sidr_of_this_orma){
    try{
      console.log("retailer_id", retailer_id);
      let res = (await this.http.post(environment.API + `/sme/getAllSidrNoEvenIfPaid/${retailer_id}`, {sidr_of_this_orma: sidr_of_this_orma})
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async showProgress(){
    this._progress.show("Loading...");
  }

  async getDaily(retailer, date, val){
    try{
      console.log("date", date);
      let res = (await this.http.get(environment.API + `/sme/getDaily/${retailer}/${date}/${val}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async getBranchApp(branch_id) {
    try {
      let res = (await this.http.get(environment.API + `/loan/getBranchApplicants/${branch_id}`,)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log(err);
    }
  }

  async updatecutOffPeriodAndDueDate(account_number, retailer_id, data) {
    try {
      let res = (await this.http.post(environment.API + `/sme/updatecutOffPeriodAndDueDate`, { account_number: account_number, retailer_id: retailer_id, data })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Cut off period and Due date Updated", 'success');
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("Error:", err);
    }
  }




}
