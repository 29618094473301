import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.scss']
})
export class PaymentSuccessPageComponent implements OnInit {
  paymentType: string
  amount: string

  constructor(
    private _route: ActivatedRoute
  ) { 
    // this.paymentType = this._route.snapshot.queryParams['paymentType']
    // this.amount = this._route.snapshot.queryParams['amount']
  }

  ngOnInit() {
    let data = '{"status": "eWallet Payment Success"}'
    let parsed = JSON.parse(data)
    console.log("parsed", parsed);
    window.postMessage(data, null);
  }

 
  confirm() {

  }

}
