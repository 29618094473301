import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { ApplicantsService } from "src/app/services/applicants.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { RetailerService } from "src/app/services/retailer.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { GeneratePdfService } from "src/app/services/generate-pdf.service"
import { UserService } from "../services/user.service";
import { DataStoreService } from "../services/data-store.service";
import { APIResponse } from "src/utils/interface";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  user: any
  displayedColumns: string[] = ["profile", "name", "phone", "retailer", "loanCategory", "dateTime", "date_approved"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  applicants: MatTableDataSource<any>;
  applicantsSrc: any[] = [];
  // intervalID: any
  searchForm: FormGroup = null
  searchValue: any = null
  itemsFetched: number = 25
  isSearch: boolean = false
  constructor(
    private _applicants: ApplicantsService,
    private dialog: MatDialog,
    private _router: Router,
    private _gPdf: GeneratePdfService,
    private _user: UserService,
    private _ds: DataStoreService
  ) {
    this.user = JSON.parse(this._user.userData)
    console.log("user", this.user)
    if(this.user){
      if(this.user?.sme  && this.user.sme == true){
        this._router.navigate(["/sme/sme-analytics"])
      }else if (this.user?.user_level == 1) {
        // this._router.navigate(["/retailer/financing-analytics"]) 
        this._router.navigate(["/retailer/retailer-portal-home"]) 
      } else if (this.user?.user_level == 2) {
        if(this.user.retailer.sme == true){
          this._router.navigate(["/sme-branch/sme-branch-dashboard"]);
        }else{
          this._router.navigate(["/branch-dashboard"])
        }
      } else if(this.user?.user_level == 3){
        this._router.navigate(["/insurance-portal"]);
      }else if(this.user?.user_level == 4){
        this._router.navigate(["/organization-portal"]);
      }else {
        console.log("correct path");
      }
    }else{
      this._router.navigate(["/login"])
    }

    this.searchForm = new FormGroup({
      searchInput: new FormControl(null),
    })
  }

  
  applyFilter(filter: string) {
    this.applicants.filter = filter;
    if (this.applicants.paginator) {
      this.applicants.paginator.firstPage();
    }
  }

  updateSearchValue(){
    this.searchValue = this.searchForm.value.searchInput
  }

  async searchRecord(){
    this.isSearch = true
    let searchValue = this.searchForm.value.searchInput
    this.searchValue = searchValue
    let res = await this._applicants.searchForApplicant(searchValue)
    
    let data = []
    for(let i = 0; i < res.length; i++){
      data.push({
          _id: res[i]._id,
          profile: res[i].user ? res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null : null,
          name:  res[i].user ? res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName : null,
          phone: res[i].user ? res[i].user.personalInformation.mobileNumber : null,
          retailer: res[i].retailer ? res[i].retailer ? res[i].retailer.name : res[i].product.retailer.name : null,
          loanCategory: res[i].product.loanCategory,
          dateTime: res[i].createdAt,
          date_approved: res[i].date_approved
        })
    }
    this.applicantsSrc = data
    this.dataSource()
    this.applicants.paginator.firstPage();
  }

  removeSearch(){
    this.isSearch = false
    this.searchForm.reset();
    this.searchValue = null
    this.applicantsSrc = this._ds.getAdminApplications();
    this.dataSource()
  }


  dataSource() {
    this.applicants = new MatTableDataSource(this.applicantsSrc);
    this.applicants.sort = this.sort;
    this.applicants.paginator = this.paginator;
  }

  ngOnInit() {
    let adminApplications = this._ds.getAdminApplications()
    if(adminApplications.length == 0){
      this.getApplicants();
    }else{
      this.applicantsSrc = this._ds.getAdminApplications();
      this.dataSource();
    }
  }

  async getApplicants() {
    let res = await this._applicants.getApplicants();
    for (let i = 0; i < res.length; i++) {
      this._ds.setAdminApplications({
        _id: res[i]._id,
        profile: res[i].user ? res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null : null,
        name:  res[i].user ? res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName : null,
        phone: res[i].user ? res[i].user.personalInformation.mobileNumber : null,
        retailer: res[i].retailer ? res[i].retailer ? res[i].retailer.name : res[i].product.retailer.name : null,
        loanCategory: res[i].product.loanCategory,
        dateTime: res[i].createdAt,
        date_approved: res[i].date_approved
      })
    }
    this.applicantsSrc = this._ds.getAdminApplications();
    this.dataSource();
  }

  getMoreData(){
    let adminApplications = this._ds.getAdminApplications();
    this._applicants.getMoreApplicants(this.itemsFetched).subscribe((response: APIResponse) => {
      try{
        if (response.status === "success") {
          let res = response.result
          console.log("res", res);
          for (let i = 0; i < res.length; i++) {
            this._ds.setAdminApplications(
              {
                _id: res[i]._id,
                profile: res[i].user ? res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null : null,
                name:  res[i].user ? res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName : null,
                phone: res[i].user ? res[i].user.personalInformation.mobileNumber : null,
                retailer: res[i].retailer ? res[i].retailer ? res[i].retailer.name : res[i].product.retailer.name : null,
                loanCategory: res[i].product.loanCategory,
                dateTime: res[i].createdAt,
                date_approved: res[i].date_approved
              }
            )
          }
          this.applicantsSrc = adminApplications
          this.dataSource();
        }
        this.itemsFetched += 25
      }catch(err){
        console.log("Error: ", err);
      }
    })
  }

  showMoreInfo(loan) {
    // this.showModalInfo(info);
    this._router.navigate([`loan-info`, loan._id])
  }

  showModalInfo(info) {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      panelClass: "right-modal",
      position: { right: "0%", top: "80px" },
      height: "100%",
      width: "400px",
      data: info,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result", result);
      }
    });
  }

  asignRetailer(info) {
    const dialogRef = this.dialog.open(AssignRetailerComponent, {
      panelClass: "center-modal",

      // position: { right: "0%" },
      // height: "40%",
      // width: "400px",
      data: info,
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result && result.assign_retailer != null) {
        console.log("result", result);
        let res = await this._applicants.updateApplicant(result);
        this.getApplicants();
      }
    });
  }
  generatePdfs(data) {
    console.log(data);
    this._gPdf.generatePdf(data);
  }
}

@Component({
  selector: "more-info-modal",
  templateUrl: "./more-info.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class MoreInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<MoreInfoComponent>
  ) {
    console.log("data", data);
  }

  close() {
    this._dialogRef.close();
  }
}

@Component({
  selector: "assign-retailer-modal",
  templateUrl: "./assign-retailer.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class AssignRetailerComponent {
  retailers: any[] = [];
  selectRetailerForm: FormGroup;
  id: "";
  userData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AssignRetailerComponent>,
    private _retailer: RetailerService
  ) {
    this.getRetailers();
    console.log("data", data._id);
    this.id = data._id;
    this.userData = data;
    this.selectRetailerForm = new FormGroup({
      assign_retailer: new FormControl(
        data.personalInformation.assign_retailer
          ? data.personalInformation.assign_retailer
          : null,
        Validators.required
      ),
    });
  }

  async getRetailers() {
    let res = await this._retailer.getRetailers();
    console.log("res", res);
    this.retailers = res;
  }

  close() {
    this._dialogRef.close();
  }

  submit() {
    let data = this.selectRetailerForm.value;
    data.id = this.id;
    this._dialogRef.close(data);
  }
}
