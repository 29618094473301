import { Component, OnInit, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort"
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicantsService } from '../services/applicants.service';
import { ConfirmationDialogComponent } from "../shared/dialogs/dialogs.component";

@Component({
  selector: 'app-datos-admin-done',
  templateUrl: './datos-admin-done.component.html',
  styleUrls: ['./datos-admin-done.component.scss']
})
export class DatosAdminDoneComponent implements OnInit {

  userAccount: any
  displayedColumns: string[] = ["profile", "name", "retailer", "loanCategory", "dateTime", "status",];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  done: MatTableDataSource<any>;
  doneSrc: any[] = []
  constructor(
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _router: Router,
    private _applicant: ApplicantsService
  ) {
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    console.log("this.userAccount", this.userAccount);
  }

  ngOnInit() {
    this.getDone()
  }

  applyFilter(filter: string) {
    this.done.filter = filter;
    if (this.done.paginator) {
      this.done.paginator.firstPage();
    }
  }

  dataSource() {
    this.done = new MatTableDataSource(this.doneSrc);
    this.done.sort = this.sort;
    this.done.paginator = this.paginator;
  }

  async getDone() {
    let res
    res = await this._applicant.getAllDone()
    if (res) {
      let data = []
      for (let i = 0; i < res.length; i++) {
        if(res[i].user){
          data.push(
            {
              _id: res[i]._id,
              profile: res[i].user.personalAttachment !== null ? res[i].user.personalAttachment.profile : null,
              name: res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName,
              phone: res[i].user.personalInformation.mobileNumber,
              retailer: res[i].product.retailer.name,
              loanCategory: res[i].product.loanCategory,
              dateTime: res[i].createdAt,
              status: res[i].loanStatusAdmin,
            }
          )
        }
      }

      this.doneSrc = data
      this.dataSource()
    }
  }

  async undo(user, i) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "center-modal",
      data: {
        title: "Move to Dashboard?",
        message: "this user will put back to dashbaord table.",
        cancelText: "Cancel",
        confirmText: "Confirm"
      },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        let user_id = user._id
        console.log("user_id");
        let res = await this._applicant.undoMoving(user_id)
        if (res) {
          console.log("res");
          this.doneSrc.splice(i, 1);
          this.dataSource()
        }
      }
    })
  }

  showMoreInfo(loan) {
    this._router.navigate([`loan-info`, loan._id])
  }

}
