import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicantsService } from "src/app/services/applicants.service";
import { MatTabGroup } from "@angular/material/tabs";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-applicant-info",
  templateUrl: "./applicant-info.component.html",
  styleUrls: ["./applicant-info.component.scss"],
})
export class ApplicantInfoComponent implements OnInit {
  // userAccount: any;
  // loans: any = []
  // @ViewChild("tabs", null) tabs: MatTabGroup;
  // @ViewChild("videoPlayer") videoplayer: ElementRef;
  // data: any;
  // id: string;
  // province = require("../../json/province.json");
  // city = require("../../json/city.json");
  // brgy = require("../../json/brgy.json");

  // gender_c: '';
  // civilStatus_c: '';
  // birthday_c: '';
  // findUs_c: '';
  // existingLoan_c: '';
  // kindOfLoans_c: '';

  // brgyL: any;
  // cityL: any;
  // provinceL: [];
  // ebrgyL: any;
  // ecityL: any;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _applicant: ApplicantsService,
    private dialog: MatDialog
  ) {
    // this.userAccount = JSON.parse(localStorage.getItem("user"))
    // console.log("userAccount", this.userAccount);
  }

  ngOnInit() {
    // this.id = this._route.snapshot.params["id"];
    // this.provinceL = this.province;
    // this.getApplicant();
  }

  // async getApplicant() {
  //   let res = await this._applicant.getApplicant(this.id);
  //   console.log("loans", res.loans);
  //   for (let i = 0; i < res.loans.length; i++) {
  //     if (res.loans[i].product.retailer._id == this.userAccount._id) {
  //       this.loans.push(res.loans[i])
  //     }
  //   }

  //   this.province.map((list: any) => {
  //     if (list.provCode == res.personalInformation.address.province) {
  //       res.personalInformation.address.provinceName = list.provDesc;
  //     }

  //     if (res.financial) {
  //       if (list.provCode == res.financial.employerAddress.province) {
  //         res.financial.employerAddress.provinceName = list.provDesc;
  //       }
  //     }
  //   });

  //   let records = this.city.RECORDS;
  //   let city = [];
  //   let ecity = [];
  //   await records.map((list: any) => {
  //     if (list.citymunCode == res.personalInformation.address.city) {
  //       res.personalInformation.address.cityName = list.citymunDesc;
  //     }
  //     if (res.financial) {
  //       if (list.citymunCode == res.financial.employerAddress.city) {
  //         res.financial.employerAddress.cityName = list.citymunDesc;
  //       }
  //     }
  //     if (list.provCode == res.personalInformation.address.province) {
  //       city.push(list)
  //     }

  //     if (res.financial) {
  //       if (list.provCode == res.financial.employerAddress.province) {
  //         ecity.push(list)
  //       }
  //     }

  //   });

  //   let brgyRecords = this.brgy.RECORDS;
  //   let brgy = [];
  //   let ebrgy = [];
  //   await brgyRecords.map((data) => {
  //     if (data.citymunCode == res.personalInformation.address.city) {
  //       brgy.push(data)
  //     }

  //     if (res.financial) {
  //       if (data.citymunCode == res.financial.employerAddress.city) {
  //         ebrgy.push(data)
  //       }
  //     }
  //   })


  //   this.brgyL = brgy;
  //   this.cityL = city;
  //   this.ecityL = ecity;
  //   this.ebrgyL = ebrgy;

  //   this.data = res;
  // }

  // async getbrgy() {

  // }

  // openImage(link) {
  //   window.open(link);
  // }

  // toggleVideo(event: any) {
  //   this.videoplayer.nativeElement.play();
  // }

  // async moveToTrash(user) {
  //   const dialogRef = this.dialog.open(ConfirmMoving, {
  //     panelClass: "center-modal",
  //     data: "Test Folder",
  //   });
  //   dialogRef.afterClosed().subscribe(async (result: any) => {
  //     if (result) {
  //       console.log("result", result);
  //       let user_id = user._id;
  //       console.log("user", user_id);
  //       let res = await this._applicant.moveToTrash(user_id);
  //       if (res) {
  //         this._router.navigate([""]);
  //       }
  //     }
  //   });
  // }

  // async moveToDone(user) {
  //   const dialogRef = this.dialog.open(ConfirmMoving, {
  //     panelClass: "center-modal",
  //     data: "Done",
  //   });
  //   dialogRef.afterClosed().subscribe(async (result: any) => {
  //     if (result) {
  //       let user_id = user._id;
  //       console.log("user", user_id);
  //       let res = await this._applicant.moveToDone(user_id);
  //       if (res) {
  //         this._router.navigate([""]);
  //       }
  //     }
  //   });
  // }

  // async undo(user) {
  //   const dialogRef = this.dialog.open(ConfirmMoving, {
  //     panelClass: "center-modal",
  //     data: "Dashboard",
  //   });
  //   dialogRef.afterClosed().subscribe(async (result: any) => {
  //     if (result) {
  //       let user_id = user._id;
  //       console.log("user", user_id);
  //       let res = await this._applicant.undoMoving(user_id);
  //       if (res) {
  //         this._router.navigate([""]);
  //       }
  //     }
  //   });
  // }



}

@Component({
  selector: "confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./applicant-info.component.scss"],
})
export class ConfirmMoving {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ConfirmMoving>
  ) {
    console.log("data", data);
  }

  close() {
    this._dialogRef.close();
  }

  submit() {
    this._dialogRef.close(true);
  }
}
