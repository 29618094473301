import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort"
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationDialogComponent } from "src/app/shared/dialogs/dialogs.component";
import { PaymentTransactionsService } from '../services/payment-transactions.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: 'app-payment-transactions',
  templateUrl: './payment-transactions.component.html',
  styleUrls: ['./payment-transactions.component.scss']
})
export class PaymentTransactionsComponent implements OnInit {
  retailer: any
  displayedColumns: string[] = ["name", "reference_id", "amount", 'dateCreated', 'contacts', ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dateFilter: FormGroup;
  allRecords: any[] = []
  totalAmount

  payment_transactions: MatTableDataSource<any>;
  payment_transactionsSrc: any[] = []
  constructor(
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _router: Router,
    private _pt: PaymentTransactionsService
  ) {
    this.retailer = JSON.parse(localStorage.getItem("user"));
    console.log('retailer', this.retailer);
    this.dateFilter = new FormGroup({
      dateFrom: new FormControl(new Date()),
      dateTo: new FormControl(new Date())
    })
   }

  ngOnInit() {
    this.getPaymentTransactions();
  }

  applyFilter(filter: string) {
    this.payment_transactions.filter = filter;
    if (this.payment_transactions.paginator) {
      this.payment_transactions.paginator.firstPage();
    }
  }

  dataSource() {
    this.payment_transactions = new MatTableDataSource(this.payment_transactionsSrc);
    this.payment_transactions.sort = this.sort;
    this.payment_transactions.paginator = this.paginator;
  }
  
  async getPaymentTransactions() {
    let res = await this._pt.getRetailerTransactions(this.retailer._id)
    let result = []
    for(let i = 0; i < res.length; i++) {
      let obj = {
        reference_id: res[i].reference_id,
        amount: res[i].amount,
        firstname: res[i].firstName,
        lastname: res[i].lastName,
        middlename: res[i].middleName,
        email: res[i].email,
        mobile_number: res[i].mobileNumber,
        dateCreated: res[i].createdAt,
      }
      result.push(obj)
    }
    this.allRecords = result
    this.startDateRangeFilter();
  }

  async undo(sme, i) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "center-modal",
      data: {
        title: "Move to Dashboard?",
        message: "this user will put back to dashbaord table.",
        cancelText: "Cancel",
        confirmText: "Confirm"
      },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {

    })
  }

  showMoreInfo(loan) {
    console.log("clicked");
    this._router.navigate([`loan-info`, loan.loan_id])
  }

  dateFromChanged(e){
    this.startDateRangeFilter()
  }

  dateToChanged(e){
    this.startDateRangeFilter()
  }

  startDateRangeFilter(){
    this.totalAmount = 0;

    var startDate = moment(this.dateFilter.value.dateFrom).format("MM/DD/yyyy");
    var endDate = moment(this.dateFilter.value.dateTo).format("MM/DD/yyyy");
    let sDate = new Date(startDate).getTime();
    let eDate = new Date(endDate).getTime();
    console.log('sDate', startDate);
    console.log('eDate', endDate);
    var results = this.allRecords.filter(a => {
    var date = moment(a.dateCreated).format("MM/DD/yyyy");
    let rDate = new Date(date).getTime();
      return (rDate >= sDate && rDate <= eDate);
    });
    console.log('results', results);
    
    for(let i = 0; i < results.length; i++){
      console.log('i amount', results[i].amount)
      this.totalAmount += Number(results[i].amount)
    }
    console.log('totalAmount', this.totalAmount);
    this.payment_transactionsSrc = results
    this.dataSource();
  }

  onChangeBranchFilter(){
    this.startDateRangeFilter()
  }


}
