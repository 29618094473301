import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormControlName } from "@angular/forms";
import {Router, ActivatedRoute} from "@angular/router"
import { SendPushNotificationService } from '../services/send-push-notification.service';
import { ApplicantsService } from '../services/applicants.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NotificationService } from "src/app/services/notification.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import {  Event, NavigationStart } from "@angular/router";
import { FilePreview } from "src/utils/interfaces";
import { MessagingService } from 'src/app/services/messaging.service';
import {Location} from '@angular/common';
import {
  ImageViewerDialog,
} from "src/app/shared/dialogs/dialogs.component";
@Component({
  selector: 'app-send-push-notification',
  templateUrl: './send-push-notification.component.html',
  styleUrls: ['./send-push-notification.component.scss']
})
export class SendPushNotificationComponent implements OnInit {
  user_id: string;
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  brgy = require("../../json/brgy.json");
  userDetails: any;
  retailer_id: any
  isLoaded: boolean = false
  messages: any[] = []
  userAccount: any;
  messageForm: FormGroup
  attachment1: FilePreview = null
  attachment2: FilePreview = null
  attachment3: FilePreview = null
  attachment4: FilePreview = null
  attachment5: FilePreview = null
  attachment6: FilePreview = null
  getMessagesInterval: any = null
  isSendToAll: boolean = false
  customers: any[] = []
  sendToNames: any[] = []
  filteredOptions: Observable<string[]>;
  savedTemplates: any[] = []
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _applicant: ApplicantsService,
    private _messaging: MessagingService,
    private _location: Location,
    private _dialog: MatDialog,
    private _applicants: ApplicantsService,
    private _notify: NotificationService,
    private _snp: SendPushNotificationService,
  ) { 
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    console.log('userAccount: ', this.userAccount);
    if (!this.userAccount.user_level) {
      this.retailer_id = null;
    } else {
      this.retailer_id = 
        this.userAccount.user_level == 1 || this.userAccount.user_level == 3 || this.userAccount.user_level == 4
          ? this.userAccount._id
          : this.userAccount.retailer._id;
    }
    
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.executeFunctionBeforeNavigation();
      }
    });
  }


 

  executeFunctionBeforeNavigation() {
    // Add your code here to perform actions when the user navigates away.
    clearInterval(this.getMessagesInterval)
    console.log('Navigating away. Execute your function here.');
  }

  async ngOnInit() {
    this.user_id = this._route.snapshot.params["loan_id"];
    console.log('messages loan_id', this.user_id);

    this.messageForm = new FormGroup({
      retailer: new FormControl(this.retailer_id),
      from: new FormControl(this.retailer_id),
      to: new FormControl(null),
      title: new FormControl(null),
      message: new FormControl(null),
      attachment1: new FormControl(null),
      attachment2: new FormControl(null),
      attachment3: new FormControl(null),
      attachment4: new FormControl(null),
      attachment5: new FormControl(null),
      attachment6: new FormControl(null),
      markAsRead: new FormControl(false),

      name: new FormControl(null),
      sendToNames: new FormControl(null),
      sendtoAll: new FormControl(null),
      saveAsTemplate: new FormControl(null)
    })

    this.getApplicantsForThisRetailer()
    this.filteredOptoionsFunction();
    this.getRetailerTemplates()
  }

  filteredOptoionsFunction(){
    this.filteredOptions = this.messageForm.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value.name)),) 
  }

  private _filter(value: any): string[] {
    const filterValue = value ? value.name ? value.name.toLowerCase() : value.toLowerCase() : null
    let returnValue = this.customers.filter(option => option.personalInformation.firstName.toLowerCase().includes(filterValue));
    return returnValue
  }

  allCustomerCheckChange(){
    if(this.messageForm.value.sendtoAll == true){
      this.isSendToAll = true
      this.sendToNames = []
      for(let i = 0; i < this.customers.length; i++){
        this.sendToNames.push({
          user_id: this.customers[i]._id,
          token: this.customers[i].token,
          name: this.customers[i].personalInformation.firstName + ' ' + this.customers[i].personalInformation.lastName
        })
      }
      this.messageForm.patchValue({
        name: null,
        sendToNames: this.sendToNames
      })
    }else{
      this.isSendToAll = false
      this.sendToNames = []
    }
  }

  async getApplicantsForThisRetailer() {
    let res = await this._applicants.getApplicantsForThisRetailer(this.retailer_id);
    for(let i = 0; i < res.length; i++){
      if(res[i].user){
        this.customers.push(res[i].user);
      }
    }
  }

  getPreview ( file: File, cb ) {
    const reader = new FileReader();
    reader.addEventListener( "load", () => {
      cb( reader.result );
    });
    reader.readAsDataURL( file );
  }

  addAttachment1 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment1 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment1(){
    this.attachment1 = null
  }

  addAttachment2 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment2 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment2(){
    this.attachment2 = null
  }

  addAttachment3 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment3 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment3(){
    this.attachment3 = null
  }

  addAttachment4 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment4 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment4(){
    this.attachment4 = null
  }

  addAttachment5 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment5 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment5(){
    this.attachment5 = null
  }

  addAttachment6 ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        this.attachment6 = {
          file,
          data: preview,
        }
      });
    });
  }
  removeAttachment6(){
    this.attachment6 = null
  }

  cancel(){
    clearInterval(this.getMessagesInterval);
    this._location.back();
  }

  nameSelected(value){
    let user_id = value.user_id
    let token = value.token
    let name = value.name

    let obj = this.sendToNames.find(o => o.user_id === value.user_id);
    console.log('obj', obj);

    if(!obj){
      this.sendToNames.push({
        user_id: user_id,
        token: token,
        name: name
      })
    }else{
      this._notify.message('Name Already Added!', "error");
    }

    this.messageForm.patchValue({
      name: null,
      sendToNames: this.sendToNames
    })
  }

  removeName(index){
    console.log(index);
    this.sendToNames.splice(index, 1)
  }

  useTemplate(title, message){
    this.messageForm.patchValue({
      title: title,
      message: message
    })
  }

  async sendMessage(){
    try{
      let mf = this.messageForm.value
      if(!mf.message){
        return
      }

      let data = {
        from: mf.from,
        to: mf.to,
        loan: mf.loan,
        retailer: mf.retailer,
        title: mf.title,
        message: mf.message,
        markAsRead: mf.markAsRead,
        name: mf.name,
        sendToNames: mf.sendToNames,
        sendtoAll: mf.sendtoAll,
        saveAsTemplate: mf.saveAsTemplate
      }
      let res = await this._messaging.sendMessageFromPushNotification(
        data, 
        this.attachment1, 
        this.attachment2, 
        this.attachment3,
        this.attachment4,
        this.attachment5,
        this.attachment6,
        )
        this.messages.push(res)
        this.messageForm.reset();
        this.attachment1 = null
        this.attachment2 = null
        this.attachment3 = null
        this.attachment4 = null
        this.attachment5 = null
        this.attachment6 = null
        this.sendToNames = []
        this.getRetailerTemplates()
    }catch(err){
      console.log("Send Message Error: ", err); 
    }
  }

  async deleteTemplate(template_id){
    await this._snp.deleteTemplate(template_id);
    this.getRetailerTemplates();
  }

  async getRetailerTemplates(){
    console.log('retailer_id', this.retailer_id);
    let res = await this._snp.getRetailerTemplates(this.retailer_id);
    console.log("templates", res);
    this.savedTemplates = res
  }

  async openAttachmentDialog(image) {
    const dialogRef = this._dialog.open(ImageViewerDialog, {
      panelClass: "center-modal",
      data: image
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        return result
      }
    });
  }

}


@Component({
  selector: 'message-content-dialog',
  templateUrl: './message-content-dialog.html',
  styleUrls: ['./send-push-notification.component.scss']
})
export class MessageContentDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<MessageContentDialog>,
  ) {
    console.log("data", data);
  }

  close() {
    this._dialogRef.close();
  }
}

