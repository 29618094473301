import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmMoving } from "../applicant-info/applicant-info.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import {
  ConfirmationDialogComponent,
  ImageViewerDialog,
} from "src/app/shared/dialogs/dialogs.component";
import { InsuranceService } from "../services/insurance.service";
import { OrganizationService } from "../services/organization.service";

@Component({
  selector: 'app-user-full-details',
  templateUrl: './user-full-details.component.html',
  styleUrls: ['./user-full-details.component.scss']
})
export class UserFullDetailsComponent implements OnInit {

  member_id: string;
  data: any;
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  brgy = require("../../json/brgy.json");
  userAccount: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedTool = ""
  selectedPrintable = ""
  isInurance: boolean = false
  isOrganization: boolean = false
  
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _ins: InsuranceService,
    private _org: OrganizationService
  ) { 
    this.userAccount = JSON.parse(localStorage.getItem("user"))
  }

  ngOnInit() {
    this.member_id = this._route.snapshot.params["member_id"];
    this._route.queryParams.subscribe(params => {
      // Get the value of the 'insurance' query parameter
      if(params['insurance'] === 'true'){
        this.isInurance = true
      };
      if(params['organization'] === 'true'){
        this.isOrganization = true
      }
    });
    this.getMemberDetails()

  }
  

  getMemberDetails = async () => {
    try{
      let res;
      if(this.isInurance){
        res = await this._ins.getMember(this.member_id)
      }
      if(this.isOrganization){
        res = await this._org.getMember(this.member_id)
      }

      this.province.map((list: any) => {
        if (list.provCode == res.user.personalInformation.address.province) {
          res.user.personalInformation.address.provinceName = list.provDesc;
        }
      });


      let records = this.city.RECORDS;
      records.map((list: any) => {
        if (list.citymunCode == res.user.personalInformation.address.city) {
          res.user.personalInformation.address.cityName = list.citymunDesc;
        }
      });

      this.data = res
      console.log("🚀 ~ UserFullDetailsComponent ~ getMemberDetails= ~ data:", this.data)
    }catch(err){
      console.log("Error: ", err);
    }
  }

  onSelectTools(action) {
    console.log('action: ', action);
    switch (action) {
      case "Add Co-Maker":
        // this.addCoMaker(this.data.user._id)
        break;
      case "View Co-Maker":
        // this.viewCoMaker(this.data.user.co_maker_id.loans[0])
        break;
      case "Edit Co-Maker Account":
        // this.EditLoanInformationCoMaker(this.data)
        break;
      case "Move to Archive":
        this.moveToDone(this.data)
        break;
      case "Edit Loan Information":
        this.EditMemberInformation()
        break;
      case "Allow User to Edit":
        this.allowEdit()
        break;
    }
  }

  async moveToDone(member_id) {
    const dialogRef = this.dialog.open(ConfirmMoving, {
      panelClass: "center-modal",
      data: "Archived",
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        console.log("member_id", member_id);
        if (this.isInurance) {
          await this._ins.memberDone(member_id)
          this.data.done = true
        };
        if (this.isOrganization) {
          await this._org.memberDone(member_id)
          this.data.done = true
        }
      }
    });
  }

  

  EditMemberInformation() {
    this._router.navigate([`customer-forms`], {
      queryParams: {
        member_id: this.member_id,
      }
    })
  }

  
  async allowEdit() {
  }


}
