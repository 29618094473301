import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ApplicantInfoComponent } from "./applicant-info/applicant-info.component";
import {TrashComponent} from "./trash/trash.component";
import { DoneComponent } from './done/done.component';
import {LoanInfoComponent} from "./loan-info/loan-info.component";
import {DatosAdminDoneComponent} from "./datos-admin-done/datos-admin-done.component"
import { SendPushNotificationComponent } from './send-push-notification/send-push-notification.component';
import { DashboardReportComponent } from './dashboard-report/dashboard-report.component';
import { PaymentSuccessPageComponent } from './payment-success-page/payment-success-page.component';
import { PaymentFailurePageComponent } from './payment-failure-page/payment-failure-page.component';
import { ManagePromocodeComponent } from './manage-promocode/manage-promocode.component';
import { PaymentTransactionsComponent } from './payment-transactions/payment-transactions.component';
import { UserFullDetailsComponent } from './user-full-details/user-full-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'retailer', loadChildren: () => import('./retailer-portal/retailer-portal.module').then(m => m.RetailerPortalModule)},
  { path: 'branch-dashboard', loadChildren: () => import('./branch-portal/branch-portal.module').then(m => m.BranchPortalModule)},
  { path: 'insurance-portal', loadChildren: () => import('./insurance-portal/insurance-portal.module').then(m => m.InsurancePortalModule)},
  { path: 'organization-portal', loadChildren: () => import('./organization-portal/organization-portal.module').then(m => m.OrganizationPortalModule)},
  
  { path: 'trash', component: TrashComponent },
  { path: 'done', component: DoneComponent },
  {path: 'datos-admin-done', component: DatosAdminDoneComponent},
  { path: 'agent', loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule) },
  { path: 'applicants', loadChildren: () => import('./applicants/applicants.module').then(m => m.ApplicantsModule) },
  { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
  { path: 'ci', loadChildren: () => import('./ci/ci.module').then(m => m.CiModule) },
  { path: 'retailer', loadChildren: () => import('./retailer/retailer.module').then(m => m.RetailerModule)},
  { path: 'applicant-info/:id', component: ApplicantInfoComponent},
  { path: 'loan-category', loadChildren: () => import('./loan-category/loan-category.module').then(m => m.LoanCategoryModule)},
  { path: 'loan-info/:loan_id', component: LoanInfoComponent},
  { path: 'sme', loadChildren: () => import('./sme/sme.module').then(m => m.SmeModule)},
  { path: 'send-push-notification', component: SendPushNotificationComponent},
  { path: 'customer-forms', loadChildren: () => import('./customer-forms/customer-forms.module').then(m => m.CustomerFormsModule)},
  { path: 'product-information', loadChildren: () => import('./product-information/product-information.module').then(m => m.ProductInformationModule)},
  { path: 'sme-branch', loadChildren: () => import('./sme-branch/sme-branch.module').then(m => m.SmeBranchModule)},
  { path: 'payment-success-page', component: PaymentSuccessPageComponent},
  { path: 'payment-failure-page', component: PaymentFailurePageComponent},
  { path: 'manage-promocode', component: ManagePromocodeComponent},
  { path: 'payment-transactions', component: PaymentTransactionsComponent},
  { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule)},
  { path: 'organizations', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule)},
  { path: 'insurance', loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule)},
  { path: 'user_full_details/:member_id', component: UserFullDetailsComponent},
  

  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDemoModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
  { path: 'user-pages', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule) },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard-report', component: DashboardReportComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
