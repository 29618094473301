import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort"
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicantsService } from '../services/applicants.service';
import { ConfirmationDialogComponent } from "../shared/dialogs/dialogs.component";

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss']
})
export class TrashComponent implements OnInit {
  userAccount: any
  displayedColumns: string[] = ["profile", "name", "phone", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  trashes: MatTableDataSource<any>;
  trashSrc: any[] = []

  constructor(
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _router: Router,
    private _applicant: ApplicantsService
  ) {
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    console.log("userAccount", this.userAccount);
  }

  ngOnInit() {
    this.getTrashes()
  }

  applyFilter(filter: string) {
    this.trashes.filter = filter;
    if (this.trashes.paginator) {
      this.trashes.paginator.firstPage();
    }
  }

  dataSource() {
    this.trashes = new MatTableDataSource(this.trashSrc);
    this.trashes.sort = this.sort;
    this.trashes.paginator = this.paginator;
  }

  async getTrashes() {
    let res
    if (this.userAccount.user_level) {
      res = await this._applicant.getTrashApplicants(this.userAccount._id)
    } else {
      res = await this._applicant.getAllTrashes()
    }

    if (res) {
      let data = []
      for (let i = 0; i < res.length; i++) {
        data.push(
          {
            _id: res[i]._id,
            profile: res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null,
            name: res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName,
            phone: res[i].user.personalInformation.mobileNumber,
          }
        )
      }

      this.trashSrc = data;
      this.dataSource()
    }
  }

  async undo(user, i) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "center-modal",
      data: {
        title: "Move to Dashboard?",
        message: "this user will put back to dashbaord table.",
        cancelText: "Cancel",
        confirmText: "Confirm"
      },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        let user_id = user._id
        console.log("user_id");
        let res = await this._applicant.undoMoving(user_id, null)
        if (res) {
          console.log("res");
          this.trashSrc.splice(i, 1);
          this.dataSource()
        }
      }
    })
  }



  showMoreInfo(loan) {
    this._router.navigate([`loan-info`, loan._id])
  }


}
