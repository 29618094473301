import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {Location} from '@angular/common';
import { SmeService } from 'src/app/services/sme.service';
import {Router, ActivatedRoute} from "@angular/router"
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FilePreview} from "src/utils/interface";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: 'app-edit-sme-record',
  templateUrl: './edit-sme-record.component.html',
  styleUrls: ['./edit-sme-record.component.scss']
})
export class EditSmeRecordComponent implements OnInit {
  retialer: any = null
  issidr: boolean = false;
  isorma: boolean = false;
  sidrForm: FormGroup;
  ormaForm: FormGroup;

  sme_id_record: any = null
  sme_id: string = null

  smeUsers: any[] = []
  filteredOptions: Observable<string[]>;

  photos: FilePreview[] = [];

  sidrnos: any[] = [];
  total: number = 0

  constructor(
    private _location: Location,
    private sme: SmeService,
    private _router: Router,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.retialer = JSON.parse(localStorage.getItem("user"));
   }

  back(){
    this._location.back();
  }


  ngOnInit(){

    this.sidrForm = new FormGroup({
      sidrno: new FormControl(null),
      name: new FormControl(null),
      accountNumber: new FormControl(null),
      date: new FormControl(null),
      amount: new FormControl(null),
      remarks: new FormControl(null)
    })

    this.ormaForm = new FormGroup({
      ormano: new FormControl(null),
      name: new FormControl(null),
      accountNumber: new FormControl(null),
      date: new FormControl(null),
      rebate: new FormControl(null),
      penalty: new FormControl(null),
      amount: new FormControl(null),
      remarks: new FormControl(null),
      sidrno_from_orma_payment: new FormControl(null),
    })

    if(this._route.snapshot.queryParams['id']){
      this.sme_id = this._route.snapshot.queryParams['id'];
      this.getSMEIDDetails();
    }
    
  }

  async getAllSidrNoEvenIfPaid(sidr_of_this_orma){
    let res = await this.sme.getAllSidrNoEvenIfPaid(this.retialer._id, sidr_of_this_orma);
    this.sidrnos = res
    let filteredSidr = []
    if(this.ormaForm.value.accountNumber){
      for(let i = 0; i < this.sidrnos.length; i++){
        if(this.sidrnos[i].accountNumber == this.ormaForm.value.accountNumber){
          filteredSidr.push(this.sidrnos[i])
        }
      }
      this.sidrnos = filteredSidr
    }
    console.log("sidr's", this.sidrnos);

    for(let i = 0; i < res.length; i++){
      res[i]['checked'] = false
    }
    let paidsidr = this.sme_id_record.sidrno_from_orma_payment
    for(let i = 0; i < paidsidr.length; i++){
      for(let x = 0; x < res.length; x++){
        if(paidsidr[i].sidr == res[x].sidrno){
          res[x]['checked'] = true
          this.total += res[x].remaining_sidr_amount_balance + paidsidr[i].amountpaid + paidsidr[i].rebate
          if(res[x].paid == false){
            res[x].remaining_sidr_amount_balance += paidsidr[i].amountpaid + paidsidr[i].rebate
          }
        }
      }
    }
    
  }


  async getSMEIDDetails(){
    let res = await this.sme.getRecord(this.sme_id);
    this.sme_id_record = res  
    console.log("res", res);

    this.photos.push({
      file: res.image,
      data: res.image
    })
    if(this.sme_id_record.sidrno !== null){
      this.issidr = true

      this.sidrForm.patchValue({
        sidrno: this.sme_id_record.sidrno,
        name: this.sme_id_record.name,
        accountNumber: this.sme_id_record.accountNumber,
        date: this.sme_id_record.date,
        amount: this.sme_id_record.amount,
        remarks: this.sme_id_record.remarks
      })

    }else if(this.sme_id_record.ormano !== null){
      this.isorma = true

      this.ormaForm.patchValue({
        ormano: this.sme_id_record.ormano,
        name: this.sme_id_record.name,
        accountNumber: this.sme_id_record.accountNumber,
        date: this.sme_id_record.date,
        rebate: this.sme_id_record.rebate,
        penalty: this.sme_id_record.penalty,
        amount: this.sme_id_record.amount,
        remarks: this.sme_id_record.remarks,
      })

      this.getAllSidrNoEvenIfPaid(this.sme_id_record.sidrno_from_orma_payment);
    }

    this.getCreatedSMEAccounts();
    this.filteredOptions = this.issidr == true ? this.sidrForm.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value.name)),) 
      : 
        this.ormaForm.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value.name)),) 
  }

  private _filter(value: any): string[] {
    const filterValue = value ? value.name ? value.name.toLowerCase() : value.toLowerCase() : null
      return this.smeUsers.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  nameSelected(value){
    this.sidrForm.patchValue({
      name: value.name,
      accountNumber: value.accountNumber
    })
    this.ormaForm.patchValue({
      name: value.name,
      accountNumber: value.accountNumber
    })
    
  }

  async getCreatedSMEAccounts(){
    let res = await this.sme.getAllSMEUsers(this.retialer._id);
    this.smeUsers = res
  }



  async dateChanged(e) {
    if(this.issidr == true){
      this.sidrForm.patchValue({
        date: e.value
      });
      this.sidrFormErrorHandlers();
    }else{
      this.ormaForm.patchValue({
        date: e.value
      })
      this.ormaFormErrorHandlers()
    }
  }

  addFiles ( files: File[] ) {
    files.forEach( ( file ) => {
      this.getPreview( file, ( preview ) => {
        console.log("file", file);
        this.photos.push( {
          file,
          data: preview,
        } );
      } );
    } );
  }

  getPreview ( file: File, cb ) {
    const reader = new FileReader();
    reader.addEventListener( "load", () => {
      cb( reader.result );
    } );
    reader.readAsDataURL( file );
  }

  removePhoto () {
      this.photos = []
  }

  sidrnoError: string = null
  sidrNameError: string = null
  sidrAccountNumberError: string = null
  sidrDateError: string = null
  sidrAmountError: string = null
  sidrFormErrorHandlers(){
    let errorHandlersPass = true
    let sidr = this.sidrForm.value
    if((sidr.sidrno == null) || (sidr.sidrno == "")){
      this.sidrnoError = "SI/DR is required!"
    }else{
      this.sidrnoError = null
    }

    if((sidr.name == null) || (sidr.name == "")){
      this.sidrNameError = "Name is required!"
    }else{
      this.sidrNameError = null
    }

    if((sidr.accountNumber == null) || (sidr.accountNumber == "")){
      this.sidrAccountNumberError = "Account Number is required!"
    }else{
      this.sidrAccountNumberError = null
    }

    if((sidr.date == null) || (sidr.date == "")){
      this.sidrDateError = "Date is required!"
    }else{
      this.sidrDateError = null
    }

    if((sidr.amount == null) || (sidr.amount == "")){
      this.sidrAmountError = "Amount is required!"
    }else{
      this.sidrAmountError = null
    }


    if(
      ((sidr.sidrno == null) || (sidr.sidrno == ""))
      ||
      ((sidr.name == null) || (sidr.name == ""))
      ||
      ((sidr.accountNumber == null) || (sidr.accountNumber == ""))
      ||
      ((sidr.date == null) || (sidr.date == ""))
      ||
      ((sidr.amount == null) || (sidr.amount == ""))
      ){
        errorHandlersPass = false
    }else{
        errorHandlersPass = true
    }

    return errorHandlersPass
  }

  ormanoError: string = null
  romaNameError: string = null
  ormaAccountNumberError: string = null
  ormaAmountError: string = null
  ormaDateError: string = null
  ormaPaymentForError: string = null
  ormaFormErrorHandlers(){
    let errorHandlersPass = true
    let orma = this.ormaForm.value

    if((orma.ormano == null) || (orma.ormano == "")){
      this.ormanoError = "OR/MA is required!"
    }else{
      this.ormanoError = null
    }

    if((orma.name == null) || (orma.name == "")){
      this.romaNameError = "Name is required!"
    }else{
      this.romaNameError = null
    }

    if((orma.accountNumber == null) || (orma.accountNumber == "")){
      this.ormaAccountNumberError = "Account Number is required!"
    }else{
      this.ormaAccountNumberError = null
    }

    if((orma.amount == null) || (orma.amount == "")){
      this.ormaAmountError = "Amount is required!"
    }else{
      this.ormaAmountError = null
    }

    if((orma.date == null) || (orma.date == "")){
      this.ormaDateError = "Date is required!"
    }else{
      this.ormaDateError = null
    }

    if(
      ((orma.ormano == null) || (orma.ormano == ""))
      ||
      ((orma.name == null) || (orma.name == ""))
      ||
      ((orma.accountNumber == null) || (orma.accountNumber == ""))
      ||
      ((orma.amount == null) || (orma.amount == ""))
      ||
      ((orma.date == null) || (orma.date == ""))
      ||
      (this.total == 0)
    ){
      errorHandlersPass = false
    }else{
      errorHandlersPass = true
    }

    return errorHandlersPass
  }

  async updateRecord(){
    let res;
    if(this.sme_id_record.sidrno !== null){
      if(this.sidrFormErrorHandlers() == true){
        console.log("form is ready to submit!!!");
        res = await this.sme.updateRecord(this.sme_id_record._id, this.sidrForm.value, this.photos)
      }
    }else{
      let arr = []
      for(let i = 0; i < this.sidrnos.length; i++){
        if(this.sidrnos[i].checked == true){
          arr.push(this.sidrnos[i].sidrno);
        }
      }
      this.ormaForm.patchValue({
        sidrno_from_orma_payment: arr
      })

      if(this.ormaFormErrorHandlers() == true){
        console.log("form is ready to submit!!! ORMA");
        res = await this.sme.updateRecord(this.sme_id_record._id, this.ormaForm.value, this.photos, this.sidrnos)
      }

    }
    // console.log("res");
    this.back();
  }

  openSidrList(){
    const dialogRef = this.dialog.open(SidrEditDialog, {
      panelClass: "right-modal",
      width: '60%',
      data: this.sidrnos
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("sidr modal result", result);
        this.sidrnos = result.data
        this.total = result.total

        this.ormaFormErrorHandlers()
      }
    });
  }

}


@Component({
  selector: 'sidr-edit-dialog',
  templateUrl: './sidr-edit-modal.html',
  styleUrls: ['./edit-sme-record.component.scss']
})
export class SidrEditDialog {
  displayedColumns: string[] = ["sidrno", "Date", "amount", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  data: MatTableDataSource<any>;
  dataSrc: any[] = [];
  
  allSelected: boolean = false
  total: number = 0
  constructor(
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private _dialogRef: MatDialogRef<SidrEditDialog>,
  ) {
    this.dataSrc = datas
    this.dataSource();
    this.checkBoxChecking();
  }

  dataSource() {
    this.data = new MatTableDataSource(this.dataSrc);
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
  }

  selectAll(){
    if(this.allSelected == true){
      for(let i = 0; i < this.datas.length; i++){
        this.datas[i].checked = true
      }
    }else{
      for(let i = 0; i < this.datas.length; i++){
        this.datas[i].checked = false
      }
    }

    this.dataSrc = this.datas
    this.dataSource();
    this.checkBoxChecking();
  }

  checkBoxChecking(){
    this.total = 0
    let checkAll = true
      for(let i = 0 ; i < this.datas.length; i++){
        if(this.datas[i].checked == false){
          checkAll = false
        }else{
          this.total += this.datas[i].remaining_sidr_amount_balance !== 0 ? this.datas[i].remaining_sidr_amount_balance : this.datas[i].amount
        }
      }

      if(checkAll == false){
        this.allSelected = false
      }else{
        this.allSelected = true
      }
  }

  close() {
    this._dialogRef.close();
  }

  submit(){
    console.log("on submit", this.datas);
    let result = {
      data: this.datas,
      total: this.total
    }
    this._dialogRef.close(result);
  }

}
