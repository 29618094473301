import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Credential, APIResponse } from "src/utils/interfaces";
import { environment } from "src/environments/environment";
import { ReplaySubject } from "rxjs";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userAccount$: ReplaySubject<any> = new ReplaySubject<any>(1);
  firebaseUser: ReplaySubject<firebase.User> = new ReplaySubject<firebase.User>(
    1
  );
  constructor(
    private router: Router,
    private http: HttpClient,
    private _fireAuth: AngularFireAuth,
    private _progress: ProgressLoaderService,
    private _notify: NotificationService
  ) {
    _fireAuth.auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .catch((err) => console.error(err));
    _fireAuth.authState.subscribe((user) => {
      this.firebaseUser.next(user);
      if (user) {
        localStorage.setItem("firebaseUser", JSON.stringify(user));
        localStorage.setItem("welcome", "true");
      } else {
        localStorage.removeItem("firebaseUser");
      }
    });
  }

  get userData() {
    return localStorage.getItem("user")
  }


  get isLoggedIn(): boolean {
    return !!localStorage.getItem("token");
  }

  async signOut() {
    await this.removedLocalStorage();
    this.router.navigate(["/login"]);
  }

  async removedLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    return true;
  }

  async login(data) {
    if (data.email == "datosadmin2021@gmail.com") {
      try {
        let res = (await this.http
          .post(environment.API + "/adminSignin", data)
          .toPromise()) as APIResponse;
        if (res.status == "success") {
          console.log("res.result.user", res.result.user);
          localStorage.setItem("user", JSON.stringify(res.result.user))
          this.userAccount$.next(res.result.user);
          localStorage.setItem("userType", "admin");
          console.log("navigate to dashboard");
          this.router.navigate(["/dashboard"]);
          return "success";
        }
        throw new Error(res.code);
      } catch (err) {
        console.log(err);
        this._notify.message("Error Loging In", "Error")
        return err.message;
      }
    } else {
      try {
        const login = await this._fireAuth.auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );
        if (login) {
          let userUid = login.user.uid;
          console.log('uid', userUid);
          let res = (await this.http
            .get(environment.API + `/getRetailerOrBranchByUid/${userUid}`)
            .toPromise()) as APIResponse;
          if (res.status === "success") {
            localStorage.setItem("token", res.result.token);
            this.userAccount$.next(res.result.user);
            localStorage.setItem("user", JSON.stringify(res.result.user))
            let returnStatus;
            console.log("login result", res);

            if(res.result.user.user_level == 1 && res.result.user.status == "Active"){
              returnStatus = {
                status: "success",
                user: res.result.user
              };
              return returnStatus
            }
            if ((res.result.user.user_level == 1 && res.result.user.status == "Active") || (res.result.user.user_level == 2 && res.result.user.retailer.status == "Active")) {

              returnStatus = {
                status: "success",
                user: res.result.user
              };
              return returnStatus
            } else {
              returnStatus = {
                status: "Not Active",
                user: res.result.user
              };
            }

            if(res.result.user.user_level == 3 && res.result.user.status == "Active"){
              returnStatus = {
                status: "success",
                user: res.result.user
              };
              return returnStatus
            }

            if(res.result.user.user_level == 4 && res.result.user.status == "Active"){
              returnStatus = {
                status: "success",
                user: res.result.user
              };
              return returnStatus
            }

            let retailerUserReturnStatus;
            if (res.result.user.retailer && res.result.user.retailer.status == "Active") {

              retailerUserReturnStatus = {
                status: "success",
                user: res.result.user
              };


            } else {
              retailerUserReturnStatus = {
                status: "Not Active",
                user: res.result.user
              };
            }

            return returnStatus ? returnStatus : retailerUserReturnStatus
          }
        }
      } catch (error) {
        this._notify.message("Error Loging In", "Error")
        return error.message;
        // if (error.code === "auth/user-not-found") {
        //   console.log("User not found", "ERROR");
        // } else {
        //   console.log(error.message, "ERROR");
        // }
      }
    }
  }


  getRetailerOrBranchByUid = async (uid) => {
    try {
      let res = (await this.http
        .get(environment.API + `/getRetailerOrBranchByUid/${uid}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        localStorage.setItem("token", res.result.token);
        this.userAccount$.next(res.result.user);
        localStorage.setItem("user", JSON.stringify(res.result.user))
        return res.result.user
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ error:", error)
    }
  }



  navigate(user) {
    if (user.user_level == 1) {
      this.router.navigate(["/retailer"]);
    } else {
      this.router.navigate(["/branch-dashboard"]);
    }
  }

  async firebaseSignup(data) {
    try {
      this._progress.show('Setting your password...');
      let res = await this._fireAuth.auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      if (res) {
        let userUid = res.user.uid;
        if (data.userType == "retailer") {
          this._progress.hide();
          let res2 = (await this.http
            .post(environment.API + `/retailer/updateUid/${userUid}`, {
              email: data.email,
            })
            .toPromise()) as APIResponse;
          if (res2.status === "success") {
            localStorage.setItem("token", res2.result.token);
            localStorage.setItem("user", JSON.stringify(res2.result.user));
            this.userAccount$.next(res2.result.user);
            this.router.navigate(["/retailer/financing-analytics"]);
          }
        } else if(data.userType == 'branch') {
          this._progress.hide();
          let res3 = (await this.http
            .post(environment.API + `/branch/updateUid/${userUid}`, {
              email: data.email,
            })
            .toPromise()) as APIResponse;
          if (res3.status === "success") {
            localStorage.setItem("token", res3.result.token);
            localStorage.setItem("user", JSON.stringify(res3.result.user));
            this.userAccount$.next(res3.result.user);
            this.router.navigate(["/branch-dashboard"]);
          }
        } else if(data.userType == "insurance") {
          this._progress.hide();
          let res4 = (await this.http
            .post(environment.API + `/insurance/updateUid/${userUid}`, {
              email: data.email,
            })
            .toPromise()) as APIResponse;
          if (res4.status === "success") {
            localStorage.setItem("token", res4.result.token);
            localStorage.setItem("user", JSON.stringify(res4.result));
            console.log('res4.result', res4.result);
            this.userAccount$.next(res4.result);
            this.router.navigate(["/insurance-portal"]);
          }
          
        }else if(data.userType == "organization"){
          this._progress.hide();
          let res5 = (await this.http
            .post(environment.API + `/organization/updateUid/${userUid}`, {
              email: data.email,
            })
            .toPromise()) as APIResponse;
          if (res5.status === "success") {
            localStorage.setItem("token", res5.result.token);
            localStorage.setItem("user", JSON.stringify(res5.result));
            console.log('res5.result', res5.result);
            this.userAccount$.next(res5.result);
            this.router.navigate(["/organization-portal"]); 
          }
        }
      }
    } catch (error) {
      console.log('firebaseSignup Error:', error);
      switch (error.code) {
        case "auth/email-already-in-use":
          console.log("Email already in use", "ERROR");
          break;
        case "auth/weak-password":
          console.log("Password must be greater than 6 characters");
          break;
        default:
          console.log("Failed to create account", "ERROR");
      }
    }
  }

  //---------------------------------
  async updateAssignedBranch(loan_id_or_customer_id, branch) {
    try {
      this._progress.show('Updating branch assigned...')
      let res = (await this.http.post(environment.API + `/user/updateLoanAssignedBranch/${loan_id_or_customer_id}`, { branch: branch })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Assigned Branch Updated!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }


  async updateAssignedCI(loan_id_or_customer_id, ci_id) {
    try {
      this._progress.show('Updating assigned CI...')
      let res = (await this.http.post(environment.API + `/user/updateLoanAssignedCI/${loan_id_or_customer_id}`, { ci_id: ci_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Assigned CI Updated!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateLoanStatusAdmin(loan_id_or_customer_id, status) {
    try {
      this._progress.show('update loan status admin...')
      let res = (await this.http.post(environment.API + `/user/updateLoanStatusAdmin/${loan_id_or_customer_id}`, { status: status })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Status Updated!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async resetPassword(email: string) {
    try {
      this._progress.show("Loading...");
      await this._fireAuth.auth.sendPasswordResetEmail(email);
      this._progress.hide();
      this._notify.message("Link to reset password was sent on your email.", "Success")
      return true;
    } catch (error) {
      this._progress.hide();
      if (error.code === "auth/user-not-found") {
        return this._notify.message("User not found", "ERROR");
      }
      this._notify.message("Failed to reset password", "ERROR");
    }
  }


  async sendOTPCode(otpCode, email) {
    try {
      console.log('otpCode: ', otpCode);
      console.log('email', email);
      this._progress.show('Sending OTP code...')
      let res = (await this.http.post(environment.API + `/sendOTPCode`, { otpCode: otpCode, email: email })//email
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Code Sent!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("code not sent, something went wrong please contact the developers");
      console.log(err)
    }
  }



}
