import { Injectable } from '@angular/core';

interface financingAnalytics {

}

@Injectable({
  providedIn: 'root'
})

export class DataStoreService {
  adminApplications: any[] = []
  branchApplications: any[] = []
  retailerApplications: any[] = []

  retailerAnalyticsData: financingAnalytics = null

  retailerArchives: any[] = []
  retailerBranchArchives: any[] = []
  constructor(
  ) { }
  
  //--ADMIN
  setAdminApplications(data) {
    this.adminApplications.push(data)
  }
  getAdminApplications(){
    return this.adminApplications
  }


  //--BRANCH retailer branch
  setBranchApplications(data) {
    this.branchApplications.push(data)
  }
  getBranchApplications(){
    return this.branchApplications
  }


  //--RETAILER
  setRetailerAnalyticsData(data){
    this.retailerAnalyticsData = data
  }
  getRetailerAnalyticsData(data){
    return this.retailerAnalyticsData = data
  }


  setRetailerApplications(data) {
    this.retailerApplications.push(data)
  }
  getRetailerApplications(){
    return this.retailerApplications
  }

  setRetailerArchives(data){
    this.retailerArchives.push(data)
  }
  getRetailerArchives(){
    return this.retailerArchives
  }

  setRetailerBranchArchives(data){
    this.retailerBranchArchives.push(data)
  }
  getRetailerBranchArchives(){
    return this.retailerBranchArchives
  }
}
