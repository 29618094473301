import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { SmeService } from 'src/app/services/sme.service';
import { FormGroup, FormControl, Validators, FormControlName } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BranchService } from 'src/app/services/branch.service';
import { LoanService } from "src/app/services/loan.service";

@Component({
  selector: 'app-sme-dashboard',
  templateUrl: './sme-dashboard.component.html',
  styleUrls: ['./sme-dashboard.component.scss']
})
export class SmeDashboardComponent implements OnInit {
  displayedColumns: string[] = ["profile", "name", "affiliate", "phone", "address", "assigned_branch","dateTime", "approved_credit_limit",  "status", "date_approved"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  province = require("../../../../json/province.json");
  city = require("../../../../json/city.json");
  brgy = require("../../../../json/brgy.json");
  data: MatTableDataSource<any>;
  dataSrc: any[] = [];

  retialer: any = null;
  branches: any[] = []
  constructor(
    private _router: Router,
    private sme: SmeService,
    private dialog: MatDialog,
    private _branch: BranchService,
    private _loan: LoanService
  ) { 
    this.retialer = JSON.parse(localStorage.getItem("user"));
  }

  applyFilter(filter: string) {
    this.data.filter = filter;
    if (this.data.paginator) {
      this.data.paginator.firstPage();
    }
  }


  dataSource() {
    this.data = new MatTableDataSource(this.dataSrc);
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
  }


  ngOnInit() {
    this.getBranches();
    this.getSMEApplication();
    setInterval(() => {
      this.getSMEApplication();
    }, 900000)
  }

  async getBranches() {
    let res = await this._branch.getBranches(this.retialer._id)
    this.branches = res
  }

  async getSMEApplication(){
    let constructedTable = []
    let res = await this.sme.getSMEbyRetailerID(this.retialer._id)
    console.log("res", res);
    
    for (let i = 0; i < res.length; i++) {
      this.province.map((list: any) => {
        if (list.provCode == res[i].user.personalInformation.address.province) {
          res[i].user.personalInformation.address.provinceName = list.provDesc;
        }
      });

      let records = this.city.RECORDS;
      let city = [];
      let ecity = [];
      await records.map((list: any) => {
        if (list.citymunCode == res[i].user.personalInformation.address.city) {
          res[i].user.personalInformation.address.cityName = list.citymunDesc;
        }

        if (list.provCode == res[i].user.personalInformation.address.province) {
          city.push(list)
        }
      });

    }



    for(let i = 0; i < res.length; i++){
      constructedTable.push({
        _id: res[i]._id,
        user: res[i].user._id,
        retailer_id: res[i].retailer_id,
        loan_id: res[i].loan_id,
        profile: res[i].user.personalAttachment.profile,
        name: res[i].user.personalInformation.lastName + ' ' + res[i].user.personalInformation.firstName,
        affiliate: res[i].affiliate ? res[i].affiliate : null,
        account_number: res[i].account_number,
        phone: res[i].user.personalInformation.mobileNumber,
        address: res[i].user.personalInformation.address.provinceName + ' ' + res[i].user.personalInformation.address.cityName + ', ' + res[i].user.personalInformation.address.barangay + ' ' + res[i].user.personalInformation.address.street,
        assigned_branch: res[i].loan_id.assigned_branch,
        dateTime: res[i].createdAt,
        approved_credit_limit: res[i].approved_credit_limit ? res[i].approved_credit_limit : 0,
        termsDigit: res[i].termsDigit ? res[i].termsDigit : null,
        termsString: res[i].termsString ? res[i].termsString : null,
        status: res[i].status ? res[i].status : null,
        date_approved: res[i].date_approved ? res[i].date_approved : null,
        lastLoan: res[i].user.loans[res[i].user.loans.length -1]
      })
      
    }
    this.dataSrc = constructedTable
    this.dataSource()
  }

  async dateChanged(e, sme) {
    let res = await this.sme.updateDateApproved(sme.user, sme.retailer_id, sme._id, e.value);
  }

  async ChangeSMEStatus(sme, status){
    let res = this.sme.updateSMEStatus(sme.user, sme.retailer_id, sme._id, status);
  }


  async dataSourceUpdate(sme_id, res){
      this.province.map((list: any) => {
        if (list.provCode == res.user.personalInformation.address.province) {
          res.user.personalInformation.address.provinceName = list.provDesc;
        }
      });

      let records = this.city.RECORDS;
      let city = [];
      let ecity = [];
      await records.map((list: any) => {
        if (list.citymunCode == res.user.personalInformation.address.city) {
          res.user.personalInformation.address.cityName = list.citymunDesc;
        }

        if (list.provCode == res.user.personalInformation.address.province) {
          city.push(list)
        }
      });


    for(let i = 0; i < this.dataSrc.length; i++){
      if(this.dataSrc[i]._id == sme_id){
        let data = {
          _id: res._id,
          user: res.user._id,
          retailer_id: res.retailer_id,
          loan_id: res.loan_id,
          profile: res.user.personalAttachment.profile,
          name: res.user.personalInformation.lastName + ' ' + res.user.personalInformation.firstName,
          affiliate: res.affiliate ? res.affiliate : null,
          phone:res.user.personalInformation.mobileNumber,
          address: res.user.personalInformation.address.provinceName + ' ' + res.user.personalInformation.address.cityName + ', ' + res.user.personalInformation.address.barangay + ' ' + res.user.personalInformation.address.street,
          assigned_branch: res.loan_id.assigned_branch,
          dateTime:res.createdAt,
          account_number: res.account_number,
          approved_credit_limit: res.approved_credit_limit ? res.approved_credit_limit : 0,
          termsDigit: res.termsDigit ? res.termsDigit : null,
          termsString: res.termsString ? res.termsString : null,
          status: res.status ? res.status : null,
          date_approved: res.date_approved ? res.date_approved : null,
          lastLoan: res.user.loans[res.user.loans.length -1]
        }
        this.dataSrc.splice(i, 1, data)
      }
    }
    this.dataSource()
  }

  openAclDialog(sme, acl, user_id, retailer_id) {
    const dialogRef = this.dialog.open(AclDialog, {
      panelClass: "right-modal",
      data: {
        sme_id: sme._id,
        acl: acl
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        let res = await this.sme.updateCreditLimit(sme._id, result, user_id, retailer_id);
        if(res){
          this.dataSourceUpdate(sme._id, res)
        }
      }
    });
  }


  openTermsDialog(sme, termsDigit, termsString) {
    const dialogRef = this.dialog.open(TermsDialog, {
      panelClass: "right-modal",
      data: {
        sme_id: sme._id,
        termsDigit: termsDigit,
        termsString: termsString
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        let res = await this.sme.updateTerms(sme.user, sme.retailer_id, sme._id, result)
        if(res){
          this.dataSourceUpdate(sme._id, res)
        }
      }
    });
  }
  



  openAffDialog(sme, affiliate) {
    const dialogRef = this.dialog.open(AffDialog, {
      panelClass: "right-modal",
      data: {
        sme_id: sme._id,
        affiliate: affiliate,
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        let res = await this.sme.updateAffiliate(sme.user, sme.retailer_id, sme._id, result)
        if(res){
          this.dataSourceUpdate(sme._id, res)
        }
      }
    });
  }

  showMoreInfo(sme) {

    let loan_id = sme.loan_id ? sme.loan_id._id : sme.lastLoan
    let sme_id = sme._id

    this._router.navigate([`loan-info`, loan_id], { queryParams: {
      per_account_sme_id: sme_id, 
    }})
  }


  async ChangeAssignedBranch(loan, value) {
    if(value){
      let loan_id = loan.loan_id._id
      let account_number = loan.account_number
      console.log("account_number", account_number);
      let res = await this._loan.updateAssignedBranchSME(loan_id, value, account_number)
    }else{
      console.log("you cannot assign to empty branch");
    }

  }

}


@Component({
  selector: 'acl-dialog',
  templateUrl: './acl-dialog.html',
  styleUrls: ['./sme-dashboard.component.scss']
})
export class AclDialog {
  title: string
  message: string
  aclForm: FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AclDialog>,
  ) {
    this.aclForm = new FormGroup({
      acl: new FormControl(data.acl)
    })

  }

  close() {
    this._dialogRef.close();
  }

  submitacl(){
    this._dialogRef.close(this.aclForm.value);
  }

}


@Component({
  selector: 'terms-dialog',
  templateUrl: './terms-dialog.html',
  styleUrls: ['./sme-dashboard.component.scss']
})
export class TermsDialog {
  title: string
  message: string
  cut_off_due_date_Form: FormGroup
  optionsFromFirst: number[] = [];
  optionsToFirst: number[] = [];
  optionsDueDateFirst: number[] = [];
  optionsFromSecond: number[] = [];
  optionsToSecond: number[] = [];
  optionsDueDateSecond: number[] = [];
  dueDateFirstError: string = null
  dueDateSecondError: string = null
  submitError: string = null
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<TermsDialog>,
  ) {
    for(let i = 1; i <= 31; i++){
      this.optionsFromFirst.push(i);
      this.optionsToFirst.push(i)
      this.optionsDueDateFirst.push(i);
      this.optionsFromSecond.push(i),
      this.optionsToSecond.push(i),
      this.optionsDueDateSecond.push(i);
    }

    this.cut_off_due_date_Form = new FormGroup({
      cutoffPeriodFirstFrom: new FormControl(data.sme.cutoffPeriodFirstFrom ? data.sme.cutoffPeriodFirstFrom : ""),
      cutoffPeriodFirstTo: new FormControl(data.sme.cutoffPeriodFirstTo ? data.sme.cutoffPeriodFirstTo : ""),
      dueDateFirst: new FormControl(data.sme.dueDateFirst ? data.sme.dueDateFirst : ""),
      cutoffPeriodSecondFrom: new FormControl(data.sme.cutoffPeriodSecondFrom ? data.sme.cutoffPeriodSecondFrom : ""),
      cutoffPeriodSecondTo: new FormControl(data.sme.cutoffPeriodSecondTo ? data.sme.cutoffPeriodSecondTo : ""),
      dueDateSecond: new FormControl(data.sme.dueDateSecond ? data.sme.dueDateSecond :  ""),
      currentMonth_NextMonth_First: new FormControl(data.sme.currentMonth_NextMonth_First ? data.sme.currentMonth_NextMonth_First :  ""),
      currentMonth_NextMonth_Second: new FormControl(data.sme.currentMonth_NextMonth_Second ? data.sme.currentMonth_NextMonth_Second :  "")
    })

  }

  close() {
    this._dialogRef.close();
  }

  submitTerms(){
    let value = this.cut_off_due_date_Form.value;
    if((value.cutoffPeriodFirstFrom !== "") && (value.cutoffPeriodFirstFrom !== "")){
      if(value.dueDateFirst == ""){
        this.dueDateFirstError =  "Please set Due Date"
      }
    }

    if((value.cutoffPeriodSecondFrom !== "") && (value.cutoffPeriodSecondFrom !== "")){
      if(value.dueDateSecond == ""){
        this.dueDateSecondError =  "Please set Due Date"
      }
    }

    if(
      ((value.cutoffPeriodFirstFrom !== "") && (value.cutoffPeriodFirstFrom !== "")) 
      ||
      (value.cutoffPeriodSecondFrom !== "") && (value.cutoffPeriodSecondFrom !== "")
    ){
      if((this.dueDateFirstError == null) && (this.dueDateSecondError == null)){
        this._dialogRef.close(this.cut_off_due_date_Form.value);
      }
    }else{
      this.submitError = "Please Set Cut off period and Due Date";
    }
   


  }

  onChangeFields(){
    let value = this.cut_off_due_date_Form.value
    // if(value.cutoffPeriodFirstFrom !== ""){
    //   this.optionsToFirst = []
    //   let number = parseFloat(value.cutoffPeriodFirstFrom) + 1
    //   for(let i = number; i <= 31; i++){
    //     this.optionsToFirst.push(i);
    //   }
    // }
    
    // if((value.cutoffPeriodFirstFrom !== 0) && (value.cutoffPeriodFirstTo !== 0)){
    //   this.optionsFromSecond = []
    //   let number = parseFloat(value.cutoffPeriodFirstTo) + 1
    //   for(let i = number; i <= 31; i++){
    //     this.optionsFromSecond.push(i);
    //   }

    //   if(value.cutoffPeriodSecondFrom !== ""){
    //     this.optionsToSecond = []
    //     let number = parseFloat(value.cutoffPeriodSecondFrom) + 1
    //     console.log("number", number);
    //     for(let i = number; i <= 31; i++){
    //       this.optionsToSecond.push(i)
    //     }
    //   }
    // }

    if(value.dueDateFirst !== ""){
      this.dueDateFirstError =  null
    }

    if(value.dueDateSecond !== ""){
      this.dueDateSecondError =  null
    }

    this.submitError = null


  }

}


@Component({
  selector: 'aff-dialog',
  templateUrl: './affiliate-dialog.html',
  styleUrls: ['./sme-dashboard.component.scss']
})
export class AffDialog {
  title: string
  message: string
  AffForm: FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AffDialog>,
  ) {
    this.AffForm = new FormGroup({
      affiliate: new FormControl(data.affiliate),
    })

  }

  close() {
    this._dialogRef.close();
  }

  submitaff(){
    this._dialogRef.close(this.AffForm.value);
  }

}