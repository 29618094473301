import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { RetailerService } from "src/app/services/retailer.service"
import { BranchService } from 'src/app/services/branch.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  userAccount: any = null
  currentDate: string;
  constructor(
    private _user: UserService,
    private _router: Router,
    private _retailer: RetailerService,
    private _branch: BranchService,
    private dialog: MatDialog,
  ) {
    this.userAccount = JSON.parse(localStorage.getItem("user"));
    console.log("USERACCOUNT sidebar", this.userAccount);
    let otp = JSON.parse(localStorage.getItem('otp'));
      if(otp && otp.verified == false){
        this._user.signOut();
      }


    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.currentDate = mm + '/' + dd + '/' + yyyy;
    
  }

  //-not called
  checkOtpValidation(){
    let otp = JSON.parse(localStorage.getItem("otp"))
    if (otp && otp.date !== this.currentDate) {
      localStorage.removeItem("otp");
      this._user.signOut();
    }
  }

  ngOnInit() {
    this.getUserDetails()
    setInterval(() => {
      this.refreshUserData()
    }, 900000)


    const body = document.querySelector('body');
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }


  async getUserDetails() {
    if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 1) {
      let newRetailerDetailes = await this._retailer.getRetailer(this.userAccount._id)
      if (newRetailerDetailes) {
        if (newRetailerDetailes.status !== "Active") {
          this._user.signOut()
          this.userAccount = null
          this.accessDenied()
        }
      }
    } else if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 2) {
      let newBranchDetailes = await this._branch.getBranch(this.userAccount._id)
      if (newBranchDetailes) {
        if (newBranchDetailes.retailer.status !== "Active") {
          this._user.signOut()
          this.userAccount = null
          this.accessDenied()
        }
      }
    }
  }

  accessDenied() {
    const dialogRef = this.dialog.open(AccessDeniedDialog, {
      panelClass: "right-modal",
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  async refreshUserData() {
    if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 1 || this.userAccount.user_level == 3 || this.userAccount.user_level == 4 ) {
      let newRetailerDetailes = await this._retailer.getRetailer(this.userAccount._id)
      this.userAccount = newRetailerDetailes
    } else if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 2) {
      let newBranchDetailes = await this._branch.getBranch(this.userAccount._id)
      this.userAccount = newBranchDetailes
    }
  }

}

@Component({
  selector: "dialog-access-denied",
  templateUrl: "./access-denied-dialog.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AccessDeniedDialog {
  constructor(
    private _dialogRef: MatDialogRef<AccessDeniedDialog>,
  ) {
    setTimeout(() => {
      this._dialogRef.close()
    }, 15000);
  }

}

