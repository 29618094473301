import { Component, OnInit, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort"
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicantsService } from '../services/applicants.service';
import { ConfirmationDialogComponent } from "../shared/dialogs/dialogs.component";
import { DataStoreService } from "src/app/services/data-store.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent implements OnInit {
  userAccount: any
  displayedColumns: string[] = ["profile", "name", "phone", "assigned_branch", "assigned_ci", "status", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  done: MatTableDataSource<any>;
  doneSrc: any[] = []
  itemsFetched: number = 25
  isSearch: boolean = false
  searchForm: FormGroup = null
  searchValue: any = null

  constructor(
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _router: Router,
    private _applicant: ApplicantsService,
    private _ds: DataStoreService
  ) {
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    console.log("this.userAccount", this.userAccount);

    this.searchForm = new FormGroup({
      searchInput: new FormControl(null),
    })
  }

  ngOnInit() {
    this.getDone()
  }

  applyFilter(filter: string) {
    this.done.filter = filter;
    if (this.done.paginator) {
      this.done.paginator.firstPage();
    }
  }

  updateSearchValue(){
    this.searchValue = this.searchForm.value.searchInput
  }

  removeSearch(){
    this.isSearch = false
    this.searchForm.reset();
    this.searchValue = null
    this.doneSrc = this._ds.getRetailerArchives();
    this.dataSource()
  }


  async searchRecord(){
    this.isSearch = true
    let searchValue = this.searchForm.value.searchInput
    this.searchValue = searchValue
    let res = await this._applicant.searchForApplicantRetailerDone(searchValue, this.userAccount._id);
    console.log("🚀 ~ DoneComponent ~ searchRecord ~ res:", res)
    let data = []
    if (res) {
      for (let i = 0; i < res.length; i++) {
        data.push(
          {
            _id: res[i]._id,
            profile: res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null,
            name: res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName,
            phone: res[i].user.personalInformation.mobileNumber,
            assigned_branch: res[i].assigned_branch ? res[i].assigned_branch.branch_name : null,
            assigned_ci: res[i].assigned_ci ? res[i].assigned_ci.name : null,
            status: res[i].loanStatusAdmin ? res[i].loanStatusAdmin : null,
            product: res[i].product,
            loans: res[i].loans
          }
        )
      }

      this.doneSrc = data
      this.dataSource()
    }
  }

  dataSource() {
    this.done = new MatTableDataSource(this.doneSrc);
    this.done.sort = this.sort;
    this.done.paginator = this.paginator;
  }

  async getDone() {
    let res = await this._applicant.getDoneApplicants(this.userAccount._id)
    // if (this.userAccount.user_level) {
    //   res = await this._applicant.getDoneApplicants(this.userAccount._id)
    // } else {
    //   res = await this._applicant.getAllDone()
    // }
    if (res) {
      for (let i = 0; i < res.length; i++) {
        this._ds.setRetailerArchives(
          {
            _id: res[i]._id,
            profile: res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null,
            name: res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName,
            phone: res[i].user.personalInformation.mobileNumber,
            assigned_branch: res[i].assigned_branch ? res[i].assigned_branch.branch_name : null,
            assigned_ci: res[i].assigned_ci ? res[i].assigned_ci.name : null,
            status: res[i].loanStatusAdmin ? res[i].loanStatusAdmin : null,
            product: res[i].product,
            loans: res[i].loans
          }
        )
      }

      this.doneSrc = this._ds.getRetailerArchives()
      this.dataSource()
    }
  }

  getMoreData = async () => {
    let res = await this._applicant.getMoreDoneApplicants(this.userAccount._id, this.itemsFetched)
    if (res) {
      for (let i = 0; i < res.length; i++) {
        this._ds.setRetailerArchives(
          {
            _id: res[i]._id,
            profile: res[i].user.personalAttachment ? res[i].user.personalAttachment.profile : null,
            name: res[i].user.personalInformation.firstName + " " + res[i].user.personalInformation.lastName,
            phone: res[i].user.personalInformation.mobileNumber,
            assigned_branch: res[i].assigned_branch ? res[i].assigned_branch.branch_name : null,
            assigned_ci: res[i].assigned_ci ? res[i].assigned_ci.name : null,
            status: res[i].loanStatusAdmin ? res[i].loanStatusAdmin : null,
            product: res[i].product,
            loans: res[i].loans
          }
        )
      }

      this.doneSrc = this._ds.getRetailerArchives()
      this.dataSource();
      this.itemsFetched += 25
    }
  }

  async undo(loan, i) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "center-modal",
      data: {
        title: "Move to Dashboard?",
        message: "this user will put back to dashbaord table.",
        cancelText: "Cancel",
        confirmText: "Confirm"
      },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        console.log("result", result);
        let loan_id = loan._id
        console.log("user_id");
        let res = await this._applicant.undoMoving(loan_id)
        if (res) {
          console.log("res");
          this.doneSrc.splice(i, 1);
          this.dataSource()
        }
      }
    })
  }

  showMoreInfo(loan) {
    this._router.navigate([`loan-info`, loan._id])
  }
}
