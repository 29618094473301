import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "src/environments/environment";
import {APIResponse} from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";


@Injectable({
  providedIn: 'root'
})
export class SendPushNotificationService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  async sendPushNotification(retailer_id, data) {
    try {
      this._progress.show('Sending Push Notification...')
      let res = (await this.http.post(environment.API + `/spn/sendPushNotification/${retailer_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Push Notification Sent!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getRetailerTemplates(retailer_id){
      try{
        this._progress.show("Fetching retailer sme records")
        let res = (await this.http.get(environment.API + `/spn/getRetailerTemplates/${retailer_id}`)
        .toPromise()) as APIResponse
        if(res.status === "success"){
          this._progress.hide()
          return res.result
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR:", err);
      }
  }

  async getSentMessages(retailer_id){
    try{
      this._progress.show("Fetching retailer sme records")
      let res = (await this.http.get(environment.API + `/spn/getSentMessages/${retailer_id}`)
      .toPromise()) as APIResponse
      if(res.status === "success"){
        this._progress.hide()
        return res.result
      }
      throw new Error(res.code);
    }catch(err){
      console.log("ERROR:", err);
    }
  }

  async deleteTemplate(template_id) {
    try {
      let res = (await this.http.post(environment.API + `/spn/deleteTemplate/${template_id}`, {data: null})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._notify.message("Template Deleted!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

}
