import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }


  async getPromocodes(retailer_id){
    try{
      this._progress.show('Fetching Codes...')
      let res = (await this.http.get(environment.API + `/promocode/getPromocodes/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async createPromocode(code){
    try{
      this._progress.show('Creating Promo Code...')
      let res = (await this.http.post(environment.API + `/promocode/savePromocode`, {code})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async deletePromocode(code_id){
    try{
      this._progress.show('Deleting Code...')
      let res = (await this.http.delete(environment.API + `/promocode/deletePromocode/${code_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

}
