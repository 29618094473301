import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "src/environments/environment";
import {APIResponse} from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import { FilePreview } from "src/utils/interfaces";

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  getInsurance = async (ins_id) => {
    try {
      console.log('ins_id', ins_id);
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/insurance/getInsurance/${ins_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getInsurances() {
    try {
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/insurance/getInsurances`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async save(data, photos: FilePreview[]){
    try{
    console.log("data", data);
    this._progress.show('Saving Insurance...')

    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    for (let i = 0; i < photos.length; i++) {
      formData.append(`photo-${i}`, photos[i].file);
    }

    let res = (await this.http.post(environment.API + "/insurance/createInsurance", formData)
      .toPromise()) as APIResponse;
    if (res.status === "success") {
      this._progress.hide();
      this._notify.message('Insurance Saved!', 'Success')
      return res.result;
    }
    throw new Error(res.code);
    }catch(err){
      console.log("ERROR", err)
    }
  }

  async update(id, data, photos: FilePreview[]){
    try{
      console.log("data", data);
      this._progress.show('Updating insurance...')

      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }

      let res = (await this.http.post(environment.API + `/insurance/updateInsurance/${id}`,  formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('insurance Updated!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }

  deleteInsurance = async (id) => {
    try{
      this._progress.show('Deleting Insurance...')
  
      let res = (await this.http.post(environment.API + `/insurance/deleteInsurance/${id}`, {})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('Insurance deleted!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        this._progress.hide();
        console.log("ERROR", err)
      }
  }

  async updateFieldsForPI(ins_id, fields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/insurance/updateFieldsForPI/${ins_id}`, { fields: fields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFB(ins_id, fields, marriedFields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/insurance/updateFieldsForFB/${ins_id}`, { fields: fields, marriedFields: marriedFields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFS(ins_id, fields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/insurance/updateFieldsForFS/${ins_id}`, { fields: fields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFields(ins_id, fields, selfEmployedFields){
    try{
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/insurance/updateInsuranceFields/${ins_id}`, {fields: fields, selfEmployedFields: selfEmployedFields})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }
  
  getInsuranceNoAccount = async () => {
    try{
      let res = (await this.http.get(environment.API + "/insurance/getInsuranceNoAccount")
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
  }

  //members
  getInsuranceMembers = async (ins_id) => {
    try {
      console.log('ins_id', ins_id);
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/insuranceMember/getInsuranceMembers/${ins_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  getMember = async (member_id) => {
    try {
      console.log('member_id', member_id);
      this._progress.show('Fetching Member Details...')
      let res = (await this.http.get(environment.API + `/insuranceMember/getMember/${member_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  memberDone = async (member_id) => {
    try {
      this._progress.show('Moving members to archives...')
      let res = (await this.http.post(environment.API + `/insuranceMember/memberDone`, {member_id})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Member moved to Archives!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ InsuranceService ~ memberDone= ~ error:", error)
    }
  }

  undoMemberDone = async (member_id) => {
    try {
      this._progress.show('Moving members to archives...')
      let res = (await this.http.post(environment.API + `/insuranceMember/undoMemberDone`, {member_id})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Member moved to Members tab!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
    console.log("🚀 ~ InsuranceService ~ undoMemberDone= ~ error:", error)
    }
  }

  getDoneMembers = async (ins_id) => {
    try {
      console.log('ins_id', ins_id);
      this._progress.show('Fetching Members...')
      let res = (await this.http.get(environment.API + `/insuranceMember/getDoneMembers/${ins_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (error) {
      console.log("🚀 ~ InsuranceService ~ getDoneMembers= ~ error:", error)
      
    }
  }

}
