import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import { FilePreview } from "src/utils/interfaces";

@Injectable({
  providedIn: 'root'
})
export class RetailerService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }


  async getRetailers() {
    try {
      let res = (await this.http.get(environment.API + "/retailer/getRetailers")
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getRetailer(id) {
    try {
      let res = (await this.http.get(environment.API + `/retailer/getRetailer/${id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async save(data, photos: FilePreview[]) {
    try {
      console.log("data", data);
      this._progress.show('Saving Retailer...')

      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }

      let res = (await this.http.post(environment.API + "/retailer/save", formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('Retailer Saved!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async update(id, data, photos: FilePreview[]) {
    try {
      console.log("data", data);
      this._progress.show('Updating Retailer...')

      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      for (let i = 0; i < photos.length; i++) {
        formData.append(`photo-${i}`, photos[i].file);
      }

      let res = (await this.http.post(environment.API + `/retailer/update/${id}`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('Retailer Updated!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async delete(id) {
    try {
      console.log("data", id);
      this._progress.show('deleting Retailer...')
      let res = (await this.http.post(environment.API + "/retailer/delete", { id: id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message('Retailer Deleted!', 'Success')
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForPI(retailer_id, fields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/retailer/updateFieldsForPI/${retailer_id}`, { fields: fields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFB(retailer_id, fields, marriedFields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/retailer/updateFieldsForFB/${retailer_id}`, { fields: fields, marriedFields: marriedFields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFieldsForFS(retailer_id, fields, selfEmployedFields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/retailer/updateFieldsForFS/${retailer_id}`, { fields: fields, selfEmployedFields: selfEmployedFields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateFields(retailer_id, fields, selfEmployedFields) {
    try {
      this._progress.show('Updating Required Fields...')
      let res = (await this.http.post(environment.API + `/retailer/updateRetailer/${retailer_id}`, { fields: fields, selfEmployedFields: selfEmployedFields })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }




  async getRetailerUser() {
    try {
      let res = (await this.http.get(environment.API + "/retailer/getRetailerUser")
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateProductInformation(retailer_id, data) {
    try {
      this._progress.show('Updating Product Informations...')
      let res = (await this.http.post(environment.API + `/retailer/updateProductInformation/${retailer_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Updated Successfully!", "success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getProductInformation(retailer_id) {
    try {
      let res = (await this.http.get(environment.API + `/retailer/getProductInformation/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }


  async uploadAccountBalancesCsv(retailer_id, csv: FilePreview[]) {
    try {
      // this._progress.show('Uploading Records...');
      let formData = new FormData();
      formData.append(`csv`, csv[0].file);
      let res = (await this.http.post(environment.API + `/retailer/uploadAccountBalancesCsv/${retailer_id}`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Records Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);

    } catch (err) {
      console.log("ERROR:", err);
    }
  }

  async getAccountbalancesFinancing(retailer_id) {
    try {
      let res = (await this.http.get(environment.API + `/retailer/getAccountbalancesFinancing/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }
}
