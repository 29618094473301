import { Component, OnInit, ViewChild } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router"
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator"
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort"
import {ConfirmationDialogComponent} from "src/app/shared/dialogs/dialogs.component";
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { UserService } from 'src/app/services/user.service';
import { PromocodeService } from 'src/app/services/promocode.service';

@Component({
  selector: 'app-manage-promocode',
  templateUrl: './manage-promocode.component.html',
  styleUrls: ['./manage-promocode.component.scss']
})
export class ManagePromocodeComponent implements OnInit {
  userAccount: any = null
  displayedColumns: string[] = ["code", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  codes: MatTableDataSource<any>;
  codeSrc: any[] = []
  
  codeForm: FormGroup = null;

  isEdit: boolean = false
  edit_code_id: string = null
  constructor(
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _pc: PromocodeService,
    private _user: UserService,
  ) { 
    this.userAccount = JSON.parse(localStorage.getItem("user"));
    console.log("this.userAccount<--", this.userAccount._id);
  }

  ngOnInit(){
    this.initForm()
    this.getCodes(this.userAccount._id)
  }

  initForm(){
    this.codeForm = new FormGroup({
      code: new FormControl(null),
      retailer_id: new FormControl(this.userAccount._id)
    })
  }

  async getCodes(retailer_id){
    let res = await this._pc.getPromocodes(retailer_id);
    this.codeSrc = res
    console.log("cis", res);
    this.dataSource()
  }

  applyFilter(filter: string) {
    this.codes.filter = filter;
    if (this.codes.paginator) {
      this.codes.paginator.firstPage();
    }
  }

  dataSource() {
    this.codes = new MatTableDataSource(this.codeSrc);
    this.codes.sort = this.sort;
    this.codes.paginator = this.paginator;
  }

  async createPromocode(){
    console.log("created clicked!");
    let res = await this._pc.createPromocode(this.codeForm.value)
    this.getCodes(this.userAccount._id)
    this.codeForm.patchValue({
      code: null
    })
  }

  async deletePromocode(code_id){
    let res = await this._pc.deletePromocode(code_id)
    console.log("res", res);
    for(let i = 0; i < this.codeSrc.length; i++){
      if( this.codeSrc[i]._id == code_id){
        this.codeSrc.splice(i, 1)
      }
    }
    this.dataSource()
  }

  async openDeleteCodeDialog(id){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // panelClass: "right-modal",
      data: {
        title: "Are you sure?",
        message: "This action cannot be undone.",
        cancelText: "Cancel",
        confirmText: "Yes"
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.deletePromocode(id);
      }
    });
  }
}
