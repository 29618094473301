import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from "src/environments/environment";
import {APIResponse} from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private _fireAuth: AngularFireAuth,
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }


    async saveBranch(retailer_id, data){
      try{
        console.log("data", data);
        this._progress.show('Saving Branch...')
        let res = (await this.http.post(environment.API + `/branch/saveRetailer/${retailer_id}`, data)
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          this._notify.message("Branch Saved", "Success")
          return res.result;
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
    }

    async getBranches(retailer_id){
      try{
        // this._progress.show('Fetching Branchess...')
        let res = (await this.http.get(environment.API + `/branch/getBranches/${retailer_id}`)
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          // this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
        }catch(err){
          console.log("ERROR", err)
        }
    }

    async deleteBranch(branch_id) {
      try{
        this._progress.show('delete Branch...')
        let res = (await this.http.post(environment.API + `/branch/deleteBranch`, {branch_id: branch_id})
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          this._notify.message("Branch Deleted!", "Success")
          return res.result;
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
    }

    async updateBranch(branch_id, data) {
      try{
        this._progress.show('update Branch...')
        let res = (await this.http.post(environment.API + `/branch/editBranch/${branch_id}`, {branch_name: data.branch_name, contact_number: data.contact_number} )
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
    }
    
    async getBranchNoAccount() {
      try{
        this._progress.show('Fetching Branch...')
        let res = (await this.http.get(environment.API + "/branch/getBranchNoAccount")
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
        }catch(err){
          console.log("ERROR", err)
        }
    }

    async getBranch(branch_id){
      try{
        this._progress.show('Fetching Branch...')
        let res = (await this.http.get(environment.API + `/branch/getBranch/${branch_id}`)
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
        }catch(err){
          console.log("ERROR", err)
        }
    }
    
    async updateAverageProcessingTime(branch_id, apt) {
      try{
        let res = (await this.http.post(environment.API + `/branch/updateAverageProcessingTime/${branch_id}`, {apt: apt} )
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          return res.result;
        }
        throw new Error(res.code);
      }catch(err){
        console.log("ERROR", err)
      }
    }
}
