export const environment = {
  production: false,
  // API: "http://localhost:5000/api", //local
  API: "https://retailer.datos.ph/api", //live server 
  // API: "http://13.214.128.75/api", //New Testing server
  firebaseConfig: {
    apiKey: "AIzaSyCAss639OZiOyRW32FtZKBP53jCfn68q2A",
    authDomain: "ci-app-5e818.firebaseapp.com",
    projectId: "ci-app-5e818",
    storageBucket: "ci-app-5e818.appspot.com",
    messagingSenderId: "333344526493",
    appId: "1:333344526493:web:d398ade610d6b89876c474",
    measurementId: "G-DHHHDZT9WE"
  }
};