import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: 'app-dialogs',
  templateUrl: './templates/confirmation-alert.html',
  styleUrls: ['./dialogs.component.scss']
})
export class ConfirmationDialogComponent {
  title: string
  message: string
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {
    this.title = data.title
    this.message = data.message
  }

  close() {
    this._dialogRef.close();
  }

  submit(){
    this._dialogRef.close(true);
  }

}

@Component({
  selector: 'qrcode-dialog',
  templateUrl: './templates/qrcode-dialog.html',
  styleUrls: ['./dialogs.component.scss']
})
export class QrcodeDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<QrcodeDialogComponent>,
  ) {
    console.log("data", this.data);
  }

  downloadQRCode(){
      const fileNameToDownload = 'image_qrcode';
      const base64Img = document.getElementsByClassName('qrCodeImg')[0].children[0]['src'];
      fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileNameToDownload;
            link.click();
      })
  }

  close() {
    this._dialogRef.close();
  }

  submit(){
    this._dialogRef.close(true);
  }
}


@Component({
  selector: 'app-dialogs',
  templateUrl: './templates/image-viewer.html',
  styleUrls: ['./dialogs.component.scss']
})
export class ImageViewerDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ImageViewerDialog>,
  ) {
  }

  close() {
    this._dialogRef.close();
  }

  submit(){
    this._dialogRef.close(true);
  }

}

@Component({
  selector: 'app-dialogs',
  templateUrl: './templates/alert-notifications.html',
  styleUrls: ['./dialogs.component.scss']
})
export class AlertNotificationsDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AlertNotificationsDialog>,
  ) {
  }

  close() {
    this._dialogRef.close();
  }

  submit(){
    this._dialogRef.close(true);
  }

}

