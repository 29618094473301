import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
@Injectable({
  providedIn: 'root'
})
export class CiService {

  constructor(
    private _fireAuth: AngularFireAuth,
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  async saveCi(retailer_id, data) {
    try {
      console.log("data", data);
      this._progress.show('Saving CI...')
      let res = (await this.http.post(environment.API + `/ci/saveCi/${retailer_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Credit Investigator Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getAllCi(retailer_id) {
    try {
      // this._progress.show('Fetching CI...')
      let res = (await this.http.get(environment.API + `/ci/getAllCi/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async updateCi(ci_id, data) {
    try {
      this._progress.show('update CI...')
      let res = (await this.http.post(environment.API + `/ci/updateCi/${ci_id}`, { data: data })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Credit Investigator Updated!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async deleteCi(ci_id) {
    try {
      this._progress.show('delete CI...')
      let res = (await this.http.post(environment.API + `/ci/deleteCi`, { ci_id: ci_id })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Credit Investigator Deleted!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }
}
