import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { UserService } from "src/app/services/user.service";
import { RetailerService } from "src/app/services/retailer.service";
import { BranchService } from "src/app/services/branch.service";
import { InsuranceService } from "../services/insurance.service";
import { OrganizationService } from "../services/organization.service";
import { 
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";

/**
 * Password form control
 * @const {FormControl}
 */
const password = new FormControl("", Validators.required);
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isNewUser: boolean = false;
  retailer: any[] = [];
  branch: any[] = [];
  insurances: any[] = []
  organizatons: any[] = []
  userType: string;
  isSignin: boolean = true
  error: String = null
  loginErrorMessage: String = null
  showOtpScreen: boolean = false
  inputOTPCode: string;
  currentDate: string;
  loginEmailUsed: string;
  otpCodeError: string = null
  constructor(
    private _user: UserService,
    private _retailer: RetailerService,
    private _branch: BranchService,
    private dialog: MatDialog,
    private _router: Router,
    private _notify: NotificationService,
    private _ins: InsuranceService,
    private _org: OrganizationService
  ) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.currentDate = mm + '/' + dd + '/' + yyyy;
  }

  async ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, CustomValidators.email]),
      password: password,
      repassword: new FormControl(""),
    });
    this.getRetailers();
    this.getBranches();
    this.getInsurances();
    this.getOrganizations();
  }

  //-not called
  checkOtpValidation(){
    let otp = JSON.parse(localStorage.getItem("otp"))
    if (otp && otp.date !== this.currentDate) {
      localStorage.removeItem("otp");
      // this._user.signOut();
    }
  }



  switchForm() {
    this.isSignin = !this.isSignin
    this.error = null
  }

  async getRetailers() {
    let res = await this._retailer.getRetailerUser();
    this.retailer = res;
  }

  async getBranches() {
    let res = await this._branch.getBranchNoAccount();
    this.branch = res;
  }

  getInsurances = async () => {
    try{
      let res = await this._ins.getInsuranceNoAccount()
      this.insurances = res
    }catch(err){
      console.log("getInsurances err:", err);
    }
  }

  getOrganizations = async () => {
    try{
      let res = await this._org.getOrganizationNoAccount();
      this.organizatons = res
    }catch(err){
      console.log("Error: ", err);
    }
  } 

  async login() {
    let data = this.loginForm.value;
    if (this.isNewUser == false) {
      console.log(this.loginForm.value);
      let res = await this._user.login(this.loginForm.value);
      console.log("res", res);
      if (res.status == "success") {
        this.sendOTPCode(res.user, res.user.email);
      } else 
      if (res.status == "Not Active") {
        this.loginErrorMessage = "Your account is on hold because of a problem with your last payment. Please contact our Team at 0966-210-7000. Thank you."
      } else {
        this.loginErrorMessage = res
      }
      
    } else {
      if (data.password != data.repassword) {
        console.log("Password And Retype password must be equal");
        if (data.repassword == null || data.repassword == "") {
          this.error = "Please confirm your password!"
        } else {
          this.error = "Password and Confirm Password must be the same!"
        }
      } else {
        data['userType'] = this.userType;
        this._user.firebaseSignup(data);
      }
    }
  }

  async sendOTPCode(user, email) {
    console.log("HERE!!");
    //----MIAN OTP FUNCTION-----
    let otpCode = Math.floor(100000 + Math.random() * 900000)
    let res = await this._user.sendOTPCode(otpCode, email)
    localStorage.setItem("otp", JSON.stringify({ otpCode: otpCode, verified: false, date: this.currentDate }));
    this.showOtpScreen = true
    console.log(otpCode);

    //-- temporary function -remove after otp is fixed
    // let otpCode = Math.floor(100000 + Math.random() * 900000)
    // localStorage.setItem("otp", JSON.stringify({ otpCode: otpCode, verified: true, date: this.currentDate }));
    // console.log(otpCode);
    // this.verifyOTPCode();
  }

  navigate(user) {
    console.log('user -->', user);
    if(user.sme && user.sme == true){
      this._router.navigate(["/sme"]);
    }else if (user.user_level == 1) {
      // this._router.navigate(["/retailer/financing-analytics"]); //if financing is ready
      this._router.navigate(["/retailer/retailer-portal-home"]); 
    }else if(user.user_level == 3){
      this._router.navigate(["/insurance-portal"]); //if financing is ready
    }else if(user.user_level == 4){
      this._router.navigate(["/organization-portal"]); //if financing is ready
    }else {
      if(user.retailer.sme == true){
        this._router.navigate(["/sme-branch/sme-branch-dashboard"]);
      }else{
        this._router.navigate(["/branch-dashboard"]);
      }
    }
  }

  verifyOTPCode() {
    //----MIAN OTP FUNCTION-----
    let otp = JSON.parse(localStorage.getItem("otp"));
    let user = JSON.parse(localStorage.getItem("user"))

    if (this.inputOTPCode == otp.otpCode) {
      otp.verified = true
      localStorage.setItem("otp", JSON.stringify(otp))

      this.navigate(user);

      this._notify.message("Successfully Logged In", "Success")

    } else {
      this.otpCodeError = "Verification code do not match!"
    }

    //-- temporary function -remove after otp is fixed
    // let user = JSON.parse(localStorage.getItem("user"))
    // this.navigate(user);
  }


  onChangeEmail(e) {
    this.loginErrorMessage = null
    this.error = null
    let retailer = this.retailer;
    let branch = this.branch;
    let insurances = this.insurances
    let organizations = this.organizatons
    let val = e.target.value;
    this.loginEmailUsed = val
    var exist = false;
    if (val.includes("@" && ".")) {

      for (let i = 0; i < retailer.length; i++) {
        if (retailer[i].email == val) {
          this.userType = "retailer";
          var exist = true;
          console.log("Retailer Email Exist ");
        }
      }
      
      for (let i = 0; i < branch.length; i++) {
        if (branch[i].email == val) {
          var exist = true;
          this.userType = "branch";
          console.log("Branch Email Exist ");
        }
      }

      for (let i = 0; i < insurances.length; i++) {
        if (insurances[i].email == val) {
          var exist = true;
          this.userType = "insurance";
          console.log("insurance Email Exist ");
        }
      }

      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].email == val) {
          var exist = true;
          this.userType = "organization";
          console.log("Organization Email Exist ");
        }
      }

      if (exist == false) {
        this.isNewUser = false;
        this.error = "This email is not registered or the password has been set already."
      } else {
        this.isNewUser = true;
        console.log("this.isNewUser ===>", this.isNewUser, "this.userType ====>", this.userType);
      }
    }
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordDialog, {
      panelClass: "right-modal",
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result", result);
        this._user.resetPassword(result)
      }
    });
  }


}


@Component({
  selector: "dialog-forgot-password",
  templateUrl: "./forgot-password-dialog.html",
  styleUrls: ["./login.component.scss"],
})
export class ForgotPasswordDialog {
  forgotPasswordForm: FormGroup;
  isEmailInvalid: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ForgotPasswordDialog>,
  ) {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null)
    })
  }

  onChangeEmail(e) {
    let val = e.target.value
    if (val.includes(".com")) {
      this.isEmailInvalid = false
    }
  }

  cancel() {
    this._dialogRef.close()
  }

  confirm() {
    this._dialogRef.close(this.forgotPasswordForm.value.email)
  }


}
