import { Injectable } from '@angular/core';
import { FilePreview } from "src/utils/interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  async sendMessage(data, attachment1: FilePreview, attachment2: FilePreview, attachment3: FilePreview, attachment4: FilePreview, attachment5: FilePreview, attachment6: FilePreview) {
    try {
      console.log("data", data);
      this._progress.show('Sending Message...')
      let formData = new FormData();
      var seen: any = [];
      let stringy = JSON.stringify(data, function (key, val) {
        if (val != null && typeof val == "object") {
          if (seen.indexOf(val) >= 0) {
            return;
          }
          seen.push(val);
        }
        return val;
      });
      formData.append("data", stringy);

      if (attachment1 && attachment1.file) {
        formData.append("attachment1", attachment1.file);
      }

      if (attachment2 && attachment2.file) {
        formData.append("attachment2", attachment2.file);
      }

      if (attachment3 && attachment3.file) {
        formData.append("attachment3", attachment2.file);
      }

      if (attachment4 && attachment4.file) {
        formData.append("attachment4", attachment2.file);
      }

      if (attachment5 && attachment5.file) {
        formData.append("attachment5", attachment2.file);
      }

      if (attachment6 && attachment6.file) {
        formData.append("attachment6", attachment2.file);
      }

      let res = (await this.http.post(environment.API + `/messages/sendMessage`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Message Successfully Sent!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  sendMessageFromPushNotification = async (data, attachment1: FilePreview, attachment2: FilePreview, attachment3: FilePreview, attachment4: FilePreview, attachment5: FilePreview, attachment6: FilePreview) => {
    try {
      console.log("data", data);
      this._progress.show('Sending Message...')
      let formData = new FormData();
      var seen: any = [];
      let stringy = JSON.stringify(data, function (key, val) {
        if (val != null && typeof val == "object") {
          if (seen.indexOf(val) >= 0) {
            return;
          }
          seen.push(val);
        }
        return val;
      });
      formData.append("data", stringy);

      if (attachment1 && attachment1.file) {
        formData.append("attachment1", attachment1.file);
      }

      if (attachment2 && attachment2.file) {
        formData.append("attachment2", attachment2.file);
      }

      if (attachment3 && attachment3.file) {
        formData.append("attachment3", attachment2.file);
      }

      if (attachment4 && attachment4.file) {
        formData.append("attachment4", attachment2.file);
      }

      if (attachment5 && attachment5.file) {
        formData.append("attachment5", attachment2.file);
      }

      if (attachment6 && attachment6.file) {
        formData.append("attachment6", attachment2.file);
      }

      let res = (await this.http.post(environment.API + `/messages/sendMessageFromPushNotification`, formData)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Message Successfully Sent!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async geUnReadtMessagesByRetailer(retailer_id) {
    try {
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/messages/geUnReadtMessagesByRetailer/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getMessageByUserAndByRetailer(user_id, retailer_id) {
    try {
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/messages/getMessageByUserAndByRetailer/${user_id}/${retailer_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

  async getUserByUserID(user_id) {
    try {
      // this._progress.show('Fetching messages...')
      let res = (await this.http.get(environment.API + `/user/getUserPlain/${user_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        // this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

}
