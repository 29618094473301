import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { RetailerService } from "src/app/services/retailer.service"
import { BranchService } from 'src/app/services/branch.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {QrcodeDialogComponent, AlertNotificationsDialog} from "../dialogs/dialogs.component";
import { MessagingService } from 'src/app/services/messaging.service'; 
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  userAccount: any = null
  JSONQRCode: string = 'empty'
  unReadMessages: any[] = []
  retailer_id: any = null
  constructor(config: NgbDropdownConfig,
    private _user: UserService,
    private _retailer: RetailerService,
    private _branch: BranchService,
    private dialog: MatDialog,
    private _messaging: MessagingService,
    private _router: Router,

  ) {
    config.placement = 'bottom-right';
    this.userAccount = JSON.parse(localStorage.getItem("user"))
    if(this.userAccount && this.userAccount.user_level){
      if(this.userAccount.user_level && this.userAccount.user_level == 1){
        this.retailer_id = this.userAccount._id
        this.JSONQRCode = JSON.stringify({retailer_id: this.userAccount._id, loan_category: this.userAccount.loan_category})
      }else if(this.userAccount.user_level && this.userAccount.user_level == 2){
        this.retailer_id = this.userAccount.retailer._id
        this.JSONQRCode = JSON.stringify({retailer_id: this.userAccount.retailer._id, loan_category: this.userAccount.loan_category})
      }
    }
  }

  ngOnInit() {
    this.getUserDetails()

    if(this.userAccount && this.userAccount.user_level && this.userAccount?.user_level !== 3 && this.userAccount.user_level !== 4 ){
      console.log('inside checking');
      this.getUnReadMessages()
      setInterval(() => {
        this.getUnReadMessages()
      }, 5000)
    }
    setInterval(() => {
      this.refreshUserData()
    }, 900000)
  }

  getUnReadMessages = async () => {
    try{
      let res =  await this._messaging.geUnReadtMessagesByRetailer(this.retailer_id)
      this.unReadMessages = res
    }catch(err){
      console.log('Error: ', err);
    }
  }

  openQRCodeDialog(JSONQRCode) {
    const dialogRef = this.dialog.open(QrcodeDialogComponent, {
      panelClass: "right-modal",
      data: JSONQRCode
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result");
      }
    });
  }

  openAlertsDialog() {
    const dialogRef = this.dialog.open(AlertNotificationsDialog, {
      panelClass: "right-modal",
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result");
      }
    });
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

  async getUserDetails() {
    if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 1) {
      let newRetailerDetailes = await this._retailer.getRetailer(this.userAccount._id)
      if (newRetailerDetailes) {
        if (newRetailerDetailes.status !== "Active") {
          this._user.signOut()
          this.userAccount = null
        }
      }
    } else if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 2) {
      let newBranchDetailes = await this._branch.getBranch(this.userAccount._id)
      if (newBranchDetailes) {
        if (newBranchDetailes.retailer.status !== "Active") {
          this._user.signOut()
          this.userAccount = null
        }
      }
    }
  }

  async refreshUserData() {
    if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 1) {
      let newRetailerDetailes = await this._retailer.getRetailer(this.userAccount._id)
      this.userAccount = newRetailerDetailes
    } else if (this.userAccount && this.userAccount.user_level && this.userAccount.user_level == 2) {
      let newBranchDetailes = await this._branch.getBranch(this.userAccount._id)
      this.userAccount = newBranchDetailes
    }
  }

  async signOut() {
    this._user.signOut();
  }

  goToAlert(user_id){
    console.log('user_id', user_id);
    this._router.navigate([`messages/message`, user_id])
  }
}
